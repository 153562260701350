import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import SinPermisos from '../../vistas/auth/SinPermisos'
import Volver from '../../elementos/Volver'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetVendedoresMutation } from '../../../store/api/endpoints/vendedoresApiSlice'
import { useGetClienteMutation, useUpdateMarcaClienteMutation } from '../../../store/api/endpoints/clientesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'
import Select from '../../elementos/Select'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import '../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CheckIcon from '@mui/icons-material/Check'
import PersonIcon from '@mui/icons-material/Person'
import HttpIcon from '@mui/icons-material/Http'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import LanguageIcon from '@mui/icons-material/Language'
import PlaceIcon from '@mui/icons-material/Place'

export default function ClienteUpdateMarca() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getVendedores,
        {
            data: responseVendedores,
            isUninitialized: isUninitializedVendedores,
            isLoading: isLoadingVendedores,
            isSuccess: isSuccessVendedores,
            isError: isErrorVendedores,
        }
    ] = useGetVendedoresMutation()

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
        }
    ] = useGetClienteMutation()

    const [
        updateMarcaCliente,
        {
            data: responseUpdate,
            isUninitialized: isUninitializedUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdate,
            error: errorDataUpdate
        }
    ] = useUpdateMarcaClienteMutation()


    const [marca_nombre, setMarcaNombre] = useState('')
    const [marca_dominio, setMarcaDominio] = useState('')
    const [marca_vendedor, setMarcaVendedor] = useState('')
    const [marca_lenguaje, setMarcaLenguaje] = useState('ar')
    const [marca_email, setMarcaEmail] = useState('')
    const [marca_tel_whatsapp, setMarcaTelWhatsapp] = useState('')
    const [marca_tel_fijo, setMarcaTelFijo] = useState('')
    const [marca_direccion, setMarcaDireccion] = useState('')
    const [marca_mapa, setMarcaMapa] = useState('')

    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            vendedor: marca_vendedor,
            lenguaje: marca_lenguaje,
            nombre: marca_nombre,
            email: marca_email,
            tel_whatsapp: marca_tel_whatsapp,
            tel_fijo: marca_tel_fijo,
            mapa: marca_mapa,
            direccion: marca_direccion,
        }

        updateMarcaCliente({id: urlParams.id, body: body})

    }

    useEffect(() => {
        getVendedores({})
        getCliente({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetCliente) {
            setMarcaNombre(responseGetCliente.marca.marca)
            setMarcaDominio(responseGetCliente.dominio)
            setMarcaVendedor(responseGetCliente.vendedor._id)
            setMarcaEmail(responseGetCliente.marca.email)
            setMarcaTelFijo(responseGetCliente.marca.tel_fijo)
            setMarcaTelWhatsapp(responseGetCliente.marca.tel_whatsapp)
            setMarcaDireccion(responseGetCliente.marca.direccion)
            setMarcaMapa(responseGetCliente.marca.mapa)
            setMarcaLenguaje(responseGetCliente.lenguaje)
        }

    }, [isSuccessGetCliente, isErrorGetCliente])

    useEffect(() => {

        if (isSuccessUpdate) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id)

        }

        if (isErrorUpdate) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdate.data.message
            }))
        }

    }, [
        isSuccessUpdate,
        isErrorUpdate
    ])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedVendedores || isLoadingVendedores || isUninitializedGetCliente || isLoadingGetCliente || isLoadingUpdate) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                start={<Volver to={"/clientes/" + urlParams.id} />}
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseGetCliente.dominio}
                        </Link>
                        <Typography color="text.primary">Marca</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre de Marca"
                                icon={<StoreIcon />}
                                value={marca_nombre}
                                onChange={setMarcaNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Dominio"
                                icon={<HttpIcon />}
                                value={marca_dominio}
                                onChange={setMarcaDominio}
                                restricciones={['alfanumerico']}
                                type="text"
                                required
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                label="Vendedor"
                                icon={<PersonIcon />}
                                value={marca_vendedor}
                                options={responseVendedores.map(function (e) {
                                    return {
                                        value: e._id,
                                        label: e.nombre
                                    }
                                })}
                                onChange={setMarcaVendedor}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Email"
                                icon={<MailOutlineIcon />}
                                value={marca_email}
                                onChange={setMarcaEmail}
                                type="mail"
                                restricciones={['email']}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Whatsapp"
                                icon={<WhatsAppIcon />}
                                helperText="Ej: 5491112341234"
                                value={marca_tel_whatsapp}
                                onChange={setMarcaTelWhatsapp}
                                type="tel"
                                restricciones={['soloNumeros']}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Tel Fijo"
                                icon={<PhoneEnabledIcon />}
                                helperText="Ej: 5491112341234"
                                value={marca_tel_fijo}
                                onChange={setMarcaTelFijo}
                                type="tel"
                                restricciones={['soloNumeros']}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Dirección"
                                icon={<PlaceIcon />}
                                value={marca_direccion}
                                onChange={setMarcaDireccion}
                                helperText="Ej: Av San Martín 123, CABA"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Mapa"
                                icon={<PlaceIcon />}
                                value={marca_mapa}
                                onChange={setMarcaMapa}
                                helperText="https://goo.gl/maps/msKkvo47bC7YKXUA7"
                                type="text"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                label="Lenguaje"
                                icon={<LanguageIcon />}
                                value={marca_lenguaje}
                                options={[{ value: 'ar', label: 'Argentina' }]}
                                onChange={setMarcaLenguaje}
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}