import { React, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import SinPermisos from '../../../vistas/auth/SinPermisos'

import { NumericFormat } from 'react-number-format'


import { useGetClienteDominioMutation, useGetClientePlanMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useGetPagosMutation } from '../../../../store/api/endpoints/pagosApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import Tabla from '../../../elementos/Tabla'
import ConfirmDelete from '../../../elementos/ConfirmDelete'
import ButtonIcon from '../../../elementos/ButtonIcon'

import '../../../../assets/css/vistaDetalleList.css'

import PaymentIcon from '@mui/icons-material/Payment'
import AddIcon from '@mui/icons-material/Add'
import DescriptionIcon from '@mui/icons-material/Description'
import EditIcon from '@mui/icons-material/Edit'

export default function ClientePagos() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const urlParams = useParams()

    const [limitCantPagos, setLimitCantPagos] = useState(null)

    const handleClick = () => {
        setLimitCantPagos(50)
    }

    const [
        getCliente,
        {
            data: responseCliente,
            isUninitialized: isUninitializedCliente,
            isLoading: isLoadingCliente,
            isSuccess: isSuccessCliente,
            isError: isErrorCliente,
        }
    ] = useGetClienteDominioMutation()

    const [
        getPlan,
        {
            data: responsePlan,
            isUninitialized: isUninitializedPlan,
            isLoading: isLoadingPlan,
            isSuccess: isSuccessPlan,
            isError: isErrorPlan,
        }
    ] = useGetClientePlanMutation()

    const [
        getPagos,
        {
            data: responsePagos,
            isUninitialized: isUninitializedPagos,
            isLoading: isLoadingPagos,
            isSuccess: isSuccessPagos,
            isError: isErrorPagos,
        }
    ] = useGetPagosMutation()

    useEffect(() => {
        getCliente({ id: urlParams.id })
        getPlan({ id: urlParams.id })
    }, [])

    useEffect(() => {
        getPagos({ idCliente: urlParams.id, limit: limitCantPagos })
    }, [limitCantPagos])

    if (permisosDelUsuario.indexOf(14) === -1) return <SinPermisos />

    if (isUninitializedCliente || isUninitializedPlan || isUninitializedPagos || isLoadingCliente || isLoadingPlan || isLoadingPagos) return <Loading />

    if (permisosDelUsuario.indexOf(14) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseCliente.dominio}
                        </Link>
                        <Typography color="text.primary">Pagos</Typography>
                    </Breadcrumbs>
                }
                icon={<PaymentIcon />}
                end={<ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<EditIcon />}
                    to={"/clientes/" + urlParams.id + "/plan-modificar"}
                >
                    Cambiar Plan
                </ButtonIcon>}
            />
            <Container component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <br /><Tabla
                            headers={['Título', 'Valor']}
                            align={['left', 'left']}
                            body={[
                                ["Nombre: ", <b>{(responsePlan.nombre ? responsePlan.nombre : 'No tiene plan asociado')}</b>],
                                ["Meses Deuda: ", <b style={{color: (responsePlan.meses_deuda > 0 ? 'red' : 'green')}}>{responsePlan.meses_deuda}</b>],
                                ["Importe: ", <b>{<NumericFormat
                                    value={responsePlan.importe}
                                    className="foo"
                                    displayType={'text'}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={'$ '}
                                />}</b>],
                                ["Alta: ", <b>{responsePlan.alta}</b>],
                                ["Cant. Pagos: ", <b>{responsePlan.cant_pagos}</b>],
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <HeaderVista
                            titulo={"Últimos Pagos"}
                            icon={<PaymentIcon />}
                            end={(permisosDelUsuario.indexOf(13) > -1 ? <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="primary"
                                startIcon={<AddIcon />}
                                to={"nuevo"}
                            >
                                Nuevo
                            </ButtonIcon> : '')}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Tabla
                            headers={['Fecha', 'Método', 'Usuario', 'Importe', 'Acciones']}
                            align={['center', 'left', 'left', 'right', 'center']}
                            body={responsePagos.map(item => {
                                return ([
                                    item.fecha,
                                    item.modalidad,
                                    item.usuario,
                                    <NumericFormat
                                        value={item.importe}
                                        className="foo"
                                        displayType={'text'}
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        prefix={'$ '}
                                    />,
                                    <>
                                        {
                                            item.factura &&
                                            <a
                                                target="_blank"
                                                href={"https://billing.doyturnos.com.ar/api/prd/pdf/" + item.factura}>
                                                <ButtonIcon
                                                    variant="outlined"
                                                    size="small"
                                                    color="primary"
                                                    startIcon={<DescriptionIcon />}
                                                    noLink
                                                ></ButtonIcon>
                                            </a>
                                        }

                                        {
                                            permisosDelUsuario.indexOf(16) !== -1 &&
                                            <ConfirmDelete
                                                httpDelete={"pagos/" + item.id}
                                                returnUrl={"/clientes/" + urlParams.id }
                                                variant="outlined"
                                                size="small"
                                                soloIcono
                                            />
                                        }
                                    </>
                                ])
                            })}
                        />
                        <div align="center">
                            <p><small>Estás visualizando los últimos {(!limitCantPagos ? 6 : limitCantPagos)} pagos.</small></p>
                            <ButtonIcon
                                variant="outlined"
                                size="small"
                                color="dark"
                                noLink
                                handleClick={handleClick}
                            >
                                Ver Todos
                            </ButtonIcon>
                        </div>

                    </Grid>
                </Grid>
            </Container>
        </>
    )

}