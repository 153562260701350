import { tiendasApi } from '../api'

export const clientesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientes: builder.mutation({
            query: (headersTemp) => ({
                url: '/clientes',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getCliente: builder.mutation({
            query: (data) => {
                const { id } = data
                return {
                    url: '/clientes/' + id,
                    method: 'GET',
                }
            }
        }),
        getClienteDominio: builder.mutation({
            query: (data) => {
                const { id } = data
                return {
                    url: '/clientes/' + id + "/dominio",
                    method: 'GET',
                }
            },
            keepUnusedDataFor: 900,
        }),
        getClienteFacturacion: builder.mutation({
            query: (data) => {
                const { id } = data
                return {
                    url: '/clientes/' + id + "/facturacion",
                    method: 'GET',
                }
            }
        }),  
        getClientePlan: builder.mutation({
            query: (data) => {
                const { id } = data
                return {
                    url: '/clientes/' + id + "/plan",
                    method: 'GET',
                }
            }
        }),        
        getConfigServicios: builder.mutation({
            query: (data) => {
                const { id } = data
                return {
                    url: '/clientes/' + id + "/config-servicios",
                    method: 'GET',
                }
            }
        }),
        getConfigArticulos: builder.mutation({
            query: (data) => {
                const { id } = data
                return {
                    url: '/clientes/' + id + "/config-articulos",
                    method: 'GET',
                }
            }
        }),
        getConfigArticulosListas: builder.mutation({
            query: (data) => {
                const { id } = data
                return {
                    url: '/clientes/' + id + "/lista-de-precios",
                    method: 'GET',
                }
            }
        }),
        createCliente: builder.mutation({
            query: (body) => ({
                url: '/clientes',
                method: 'POST',
                body: body,
            })
        }),
        updateMarcaCliente: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/clientes/' + id + "/marca",
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        updateContactoCliente: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/clientes/' + id + "/contacto",
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        updateColoresCliente: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/clientes/' + id + "/colores",
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        updateFacturacionCliente: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/clientes/' + id + "/facturacion",
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        updateConfigServicios: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/clientes/' + id + "/config-servicios",
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        updateConfigArticulos: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/clientes/' + id + "/config-articulos",
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        updateConfigArticulosListasDePrecios: builder.mutation({
            query(data){
                const { id, body, listaId } = data
                return {
                    url: '/clientes/' + id + "/lista-de-precios/" + listaId,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        updatePlan: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/clientes/' + id + "/plan",
                    method: 'PUT',
                    body: body,
                }
            }
        }),
    })
})

export const {
    useGetClientesMutation,
    useGetClienteMutation,
    useGetClienteDominioMutation,

    useGetClienteFacturacionMutation,
    useGetClientePlanMutation,
    useGetConfigServiciosMutation,
    useGetConfigArticulosMutation,
    useGetConfigArticulosListasMutation,

    useCreateClienteMutation,
    useUpdateMarcaClienteMutation,
    useUpdateContactoClienteMutation,
    useUpdateColoresClienteMutation,

    useUpdateFacturacionClienteMutation,
    useUpdateConfigServiciosMutation,
    useUpdateConfigArticulosMutation,
    useUpdateConfigArticulosListasDePreciosMutation,

    useUpdatePlanMutation,

} = clientesApiSlice