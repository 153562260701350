import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'


import { useGetClienteConfigUsuariosMutation } from '../../../../store/api/endpoints/clientes/clientesConfigUsuariosApiSlice'
import { useCreateClientesUsuarioMutation, useGetTodosLosPermisosMutation } from '../../../../store/api/endpoints/clientes/clientesUsuariosApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import SinPermisos from '../../auth/SinPermisos'
import { openFeedback } from '../../../../store/slices/feedback'

import { TiposDeUsuarios } from './TiposDeUsuarios'

import GetDominioCliente from '../../../elementos/GetDominioCliente'
import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import ClientesCropImage from '../../../elementos/ClientesCropImage'
import Input from '../../../elementos/Input'
import Select from '../../../elementos/Select'
import CheckboxList from '../../../elementos/CheckboxList'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import CheckIcon from '@mui/icons-material/Check'
import EmailIcon from '@mui/icons-material/Email'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import PasswordIcon from '@mui/icons-material/Password'


export default function UsuariosNuevo() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const urlParams = useParams()
    const navigate = useNavigate()

    const [
        getConfigUsuarios,
        {
            data: responseConfigUsuarios,
            isUninitialized: isUninitializedConfigUsuarios,
            isLoading: isLoadingConfigUsuarios,
            isError: isSuccessConfigUsuarios,
            error: isErrorConfigUsuarios,
        }
    ] = useGetClienteConfigUsuariosMutation()

    const [
        getPermisos,
        {
            data: responsePermisos,
            isUninitialized: isUninitializedPermisos,
            isLoading: isLoadingPermisos,
            isError: isSuccessPermisos,
            error: isErrorPermisos,
        }
    ] = useGetTodosLosPermisosMutation()

    const [
        createRegistro,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreateClientesUsuarioMutation()


    const [nombre, setNombre] = useState('')
    const [img_principal, setImgPrincipal] = useState('')
    const [usuario, setUsuario] = useState('')
    const [posicion, setPosicion] = useState('')
    const [email, setEmail] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')
    const [tel_fijo, setTelFijo] = useState('')
    const [contrasena, setContrasena] = useState('')
    const [rol, setRol] = useState('')
    const [permisosElegidos, setPermisosElegidos] = useState([])


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            img_principal,
            posicion,
            usuario,
            email,
            tel_whatsapp,
            tel_fijo,
            contrasena,
            permisos: permisosElegidos,
        }

        createRegistro({ idCliente: urlParams.id, body})


    }

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el registro!"
            }))

            navigate("/clientes/" + urlParams.id + "/usuarios")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])


    useEffect(() => {
        getConfigUsuarios({idCliente: urlParams.id})
        getPermisos()
    }, [])

    useEffect(() => {
        if (rol !== '') setPermisosElegidos(TiposDeUsuarios[rol].permisos)
    }, [rol])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedConfigUsuarios || isLoadingConfigUsuarios || isUninitializedPermisos || isLoadingPermisos || isLoadingCreaRegistro) return <Loading />

    var columnasPorImg = 12
    var columnasPorImgHijos = 3
    if (responseConfigUsuarios?.img_principal?.activo) {
        columnasPorImg = 8
        columnasPorImgHijos = 6
    }

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            <GetDominioCliente idCliente={urlParams.id} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/usuarios"}>
                            Usuarios
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        {
                            responseConfigUsuarios?.img_principal?.activo &&
                            <Grid item xs={12} md={4}>
                                <ClientesCropImage
                                    imagen={img_principal}
                                    setRoute={setImgPrincipal}
                                    maxSize="1000000"
                                    width={responseConfigUsuarios?.img_principal?.width}
                                    height={responseConfigUsuarios?.img_principal?.height}
                                    ruta="usuarios"
                                    formatosPermitidos="image/jpeg, image/png"
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={columnasPorImg}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={columnasPorImgHijos}>
                                    <Input
                                        label="Nombre"
                                        icon={<PeopleAltIcon />}
                                        value={nombre}
                                        onChange={setNombre}
                                        type="text"
                                        required
                                    />
                                </Grid>
                                {
                                    responseConfigUsuarios?.email?.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Email"
                                            icon={<EmailIcon />}
                                            value={email}
                                            onChange={setEmail}
                                            type="text"
                                            required={responseConfigUsuarios?.email?.required}
                                        />
                                    </Grid>
                                }
                                {
                                    responseConfigUsuarios?.tel_whatsapp?.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Whatsapp"
                                            icon={<WhatsAppIcon />}
                                            value={tel_whatsapp}
                                            onChange={setTelWhatsapp}
                                            type="text"
                                            required={responseConfigUsuarios?.tel_whatsapp?.required}
                                        />
                                    </Grid>
                                }
                                {
                                    responseConfigUsuarios?.tel_fijo?.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Fijo"
                                            icon={<LocalPhoneIcon />}
                                            value={tel_fijo}
                                            onChange={setTelFijo}
                                            type="text"
                                            required={responseConfigUsuarios?.tel_fijo?.required}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={columnasPorImgHijos}>
                                    <Input
                                        label="Usuario"
                                        icon={<PeopleAltIcon />}
                                        value={usuario}
                                        onChange={setUsuario}
                                        type="text"
                                        required
                                    />
                                </Grid>
                                {
                                    responseConfigUsuarios?.posicion?.activo &&
                                    <Grid item xs={12} md={columnasPorImgHijos}>
                                        <Input
                                            label="Posición"
                                            icon={<AccessibilityIcon />}
                                            value={posicion}
                                            onChange={setPosicion}
                                            type="text"
                                            required={responseConfigUsuarios?.posicion?.required}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12} md={columnasPorImgHijos}>
                                    <Input
                                        label="Contraseña"
                                        icon={<PasswordIcon />}
                                        value={contrasena}
                                        onChange={setContrasena}
                                        type="password"
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Select
                                label="Rol"
                                value={rol}
                                options={TiposDeUsuarios.map((item, index) => ({
                                    value: index,
                                    label: item.nombre
                                }))}
                                onChange={setRol}
                            />
                            <br />
                            <CheckboxList
                                titulo="Permisos"
                                opciones={responsePermisos}
                                elegidos={permisosElegidos}
                                handleChange={setPermisosElegidos}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}

