import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import Loading from '../../../elementos/Loading'
import SinPermisos from '../../../vistas/auth/SinPermisos'
import SwitchInput from '../../../elementos/SwitchInput'
import { openFeedback } from '../../../../store/slices/feedback'

import { useGetConfigServiciosMutation, useUpdateConfigServiciosMutation } from '../../../../store/api/endpoints/clientesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import GetDominioCliente from '../../../elementos/GetDominioCliente'
import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'

import Button from '@mui/material/Button'
import { Divider } from '@mui/material'

import '../../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import CheckIcon from '@mui/icons-material/Check'

export default function CliConfigServicios() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
        }
    ] = useGetConfigServiciosMutation()

    const [
        updateConfig,
        {
            data: responseUpdate,
            isUninitialized: isUninitializedUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdate,
            error: errorDataUpdate
        }
    ] = useUpdateConfigServiciosMutation()


    const [activo, setActivo] = useState(false)
    const [precio, setPrecio] = useState(false)
    const [descripcion, setDescripcion] = useState(false)
    const [img_principalActivo, setImgPrincipalActivo] = useState(false)
    const [img_principalHeight, setImgPrincipalHeight] = useState(0)
    const [img_principalWidth, setImgPrincipalWidth] = useState(0)

    const [categoriasActivo, setCategoriasActivo] = useState(false)
    const [cat_img_principalActivo, setCatImgPrincipalActivo] = useState(false)
    const [cat_img_principalHeight, setCatImgPrincipalHeight] = useState(0)
    const [cat_img_principalWidth, setCatImgPrincipalWidth] = useState(0)

    const [subcategoriasActivo, setSubcategoriasActivo] = useState(false)
    const [subcat_img_principalActivo, setSubcatImgPrincipalActivo] = useState(false)
    const [subcat_img_principalHeight, setSubcatImgPrincipalHeight] = useState(0)
    const [subcat_img_principalWidth, setSubcatImgPrincipalWidth] = useState(0)


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            activo,
            precio,
            descripcion,
            img_principal: {
                activo: img_principalActivo,
                height: img_principalHeight,
                width: img_principalWidth,
            },
            categorias: {
                activo: categoriasActivo,
                img_principal: {
                    activo: cat_img_principalActivo,
                    height: cat_img_principalHeight,
                    width: cat_img_principalWidth,
                }
            },
            subcategorias: {
                activo: subcategoriasActivo,
                img_principal: {
                    activo: subcat_img_principalActivo,
                    height: subcat_img_principalHeight,
                    width: subcat_img_principalWidth,
                }
            },
        }

        updateConfig({ id: urlParams.id, body })

    }

    useEffect(() => {
        getConfig({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetConfig) {
            setActivo(responseGetConfig.activo)
            if (responseGetConfig.activo) {
                setPrecio(responseGetConfig.precio)
                setDescripcion(responseGetConfig.descripcion)
                setImgPrincipalActivo(responseGetConfig.img_principal.activo)
                setImgPrincipalHeight(responseGetConfig.img_principal.height)
                setImgPrincipalWidth(responseGetConfig.img_principal.width)

                setCategoriasActivo(responseGetConfig.categorias.activo)
                setCatImgPrincipalActivo(responseGetConfig.categorias.img_principal.activo)
                setCatImgPrincipalHeight(responseGetConfig.categorias.img_principal.height)
                setCatImgPrincipalWidth(responseGetConfig.categorias.img_principal.width)

                setSubcategoriasActivo(responseGetConfig.subcategorias.activo)
                setSubcatImgPrincipalActivo(responseGetConfig.subcategorias.img_principal.activo)
                setSubcatImgPrincipalHeight(responseGetConfig.subcategorias.img_principal.height)
                setSubcatImgPrincipalWidth(responseGetConfig.subcategorias.img_principal.width)
            }
        }

    }, [isSuccessGetConfig, isErrorGetConfig])

    useEffect(() => {

        if (isSuccessUpdate) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id + "/config")

        }

        if (isErrorUpdate) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdate.data.message
            }))
        }

    }, [
        isSuccessUpdate,
        isErrorUpdate
    ])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedGetConfig || isLoadingGetConfig || isLoadingUpdate) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            <GetDominioCliente idCliente={urlParams.id} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/config"}>
                            Config.
                        </Link>
                        <Typography color="text.primary">Servicios</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={activo}
                                onChange={setActivo}
                                helperText="Utiliza servicios"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Precios"
                                value={precio}
                                onChange={setPrecio}
                                helperText="Utiliza precios en los servicios"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Descripción"
                                value={descripcion}
                                onChange={setDescripcion}
                                helperText="Utiliza descripción en los servicios"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Img Principal"
                                value={img_principalActivo}
                                onChange={setImgPrincipalActivo}
                                helperText="Utiliza imágen principal en los articulos"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setImgPrincipalWidth}
                                value={(activo ? img_principalWidth : 0)}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && img_principalActivo}
                                disabled={!activo || !img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setImgPrincipalHeight}
                                value={(activo ? img_principalHeight : 0)}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && img_principalActivo}
                                disabled={!activo || !img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Categorías</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                onChange={setCategoriasActivo}
                                value={(activo ? categoriasActivo : false)}
                                helperText="Indica si los artículos tienen categoría."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setCatImgPrincipalActivo}
                                value={(activo && categoriasActivo ? cat_img_principalActivo : false)}
                                helperText="Indica si las categorías tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setCatImgPrincipalWidth}
                                value={(activo && categoriasActivo && cat_img_principalActivo ? cat_img_principalWidth : 0)}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && cat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !cat_img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setCatImgPrincipalHeight}
                                value={(activo && categoriasActivo && cat_img_principalActivo ? cat_img_principalHeight : 0)}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && cat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !cat_img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Subcategorías</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                onChange={setSubcategoriasActivo}
                                value={(activo && categoriasActivo ? subcategoriasActivo : false)}
                                helperText="Indica si los artículos tienen categoría."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}></Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setSubcatImgPrincipalActivo}
                                value={(activo && categoriasActivo && subcategoriasActivo ? subcat_img_principalActivo : false)}
                                helperText="Indica si las subcategorías tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setSubcatImgPrincipalWidth}
                                value={(activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo ? subcat_img_principalWidth : 0)}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !subcategoriasActivo || !subcat_img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setSubcatImgPrincipalHeight}
                                value={(activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo ? subcat_img_principalHeight : 0)}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo && categoriasActivo && subcategoriasActivo && subcat_img_principalActivo}
                                disabled={!activo || !categoriasActivo || !subcategoriasActivo || !subcat_img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}