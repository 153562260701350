import React from 'react'

import HomeIcon from '@mui/icons-material/Home'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import StoreIcon from '@mui/icons-material/Store'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'

export const SidebarData = [
    {
        titulo: 'Home',
        path: '/home',
        icono: <HomeIcon />,
        permiso: 1
    },
    {
        titulo: 'Usuarios',
        path: '/usuarios',
        icono: <SupportAgentIcon />,
        permiso: 5
    },
    {
        titulo: 'Clientes',
        path: '/clientes',
        icono: <StoreIcon />,
        permiso: 6,
    },
    {
        titulo: 'Planes',
        path: '/planes',
        icono: <AutoAwesomeMotionIcon />,
        permiso: 10
    },
]