import { tiendasApi } from '../../api'

export const clientesConfigClientesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClienteConfigClientes: builder.mutation({
            query: ({id}) => {
                return {
                    url: '/clientes/' + id + '/config-clientes',
                    method: 'GET',
                }
            }
        }),
        updateClienteConfigClientes: builder.mutation({
            query: ({id, body}) => {
                return {
                    url: '/clientes/' + id + '/config-clientes',
                    method: 'PUT',
                    body: body,
                }
            }
        }),
    })
})

export const {
    useGetClienteConfigClientesMutation,
    useUpdateClienteConfigClientesMutation,

} = clientesConfigClientesApiSlice