import { useState, useEffect } from 'react'
import { useGetUsuariosMutation } from '../../../store/api/endpoints/usuariosApiSlice'


import { useSelector } from 'react-redux'

import Loading from '../../elementos/Loading'
import ButtonIcon from '../../elementos/ButtonIcon'
import HeaderVista from '../../elementos/HeaderVista'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { TextField } from '@mui/material'

import '../../../assets/css/table.css'

import SearchIcon from '@mui/icons-material/Search'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { getUsuarioPermisos } from '../../../store/slices/auth'

const delay = 0.4

const Usuarios = () => {

    const [page, setPage] = useState(1)
    const [textsearch, setTextsearch] = useState("")

    const [
        getUsuarios,
        {
            data: returnedData,
            isUninitialized,
            isLoading,
            isSuccess,
            isError,
        }
    ] = useGetUsuariosMutation()

    const permisosDelUsuario = useSelector(getUsuarioPermisos)


    useEffect(() => {

        let timer1 = setTimeout(() => {

            //console.log("modifica texto search", textsearch)
            getUsuarios({ page, textsearch })

        }, delay * 1000);

        return () => {
            clearTimeout(timer1);
        };

    }, [textsearch, page]);



    if (isUninitialized || isLoading) return (<Loading />)

    if (isError) return (<><p>Ocurrió un error al ejecutar la consulta.</p></>)

    if (!isLoading && isSuccess) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography color="text.primary">Usuarios</Typography>
                    </Breadcrumbs>
                }
                icon={<SupportAgentIcon />}
                end={(permisosDelUsuario.indexOf(1) > -1 ? <ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to="/usuarios/nuevo"
                >
                    Nuevo
                </ButtonIcon> : '')}
            />
            <Container className="containerBigTable" component="main" maxWidth="lg">
                <Grid container>
                    <Grid className="titulo" item xs={12} md={8}>
                        <p>Se encontraron {returnedData?.totalDocs} registros.</p>
                    </Grid>
                    <Grid className="buscador" item xs={12} md={4}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                            <TextField onChange={(e) => setTextsearch(e.target.value)} fullWidth label="Buscar..." variant="standard" value={textsearch} />
                        </Box>
                    </Grid>
                </Grid>
                <div className="tableContainer">
                    <table className="formato1" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th align='left'>Nombre</th>
                                <th align='left'>Email</th>
                                <th align='center'>Whatsapp</th>
                                <th align='center'>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                returnedData?.docs.length > 0 && returnedData.docs.map((usuario, index) => {
                                    return (
                                        <tr key={index}>
                                            <td align='left'>{usuario.nombre}</td>
                                            <td align='left'>{usuario.email}</td>
                                            <td align='center'>{usuario.tel_whatsapp}</td>
                                            <td align='center'>
                                                {
                                                    permisosDelUsuario.indexOf(2) > -1 &&
                                                    <ButtonIcon
                                                        variant="outlined"
                                                        size="small"
                                                        color="primary"
                                                        startIcon={<EditIcon />}
                                                        to={'/usuarios/' + usuario._id}
                                                        marginRight
                                                    />
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                returnedData?.docs.length === 0 && <tr>
                                    <td colSpan="4">No se encontraron registros</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination
                    className="table-pagination"
                    showFirstButton showLastButton
                    shape="rounded"
                    count={returnedData?.totalPages}
                    page={returnedData?.page}
                    onChange={(event, value) => setPage(value)}
                />

            </Container>
        </>
    )

}

export default Usuarios