import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import './assets/css/Routes.css'

//Componentes Vistas
import Layout from './components/vistas/auth/Layout'
import Public from './components/vistas/auth/Public'
import RequireAuth from './components/vistas/auth/RequireAuth'
import Login from './components/vistas/auth/Login'

import Navbar from './components/vistas/Navbar'

import Home from './components/vistas/Home'

import Usuarios from './components/vistas/usuarios/Usuarios'
import UsuarioEditar from './components/vistas/usuarios/UsuarioEditar'
import UsuarioNuevo from './components/vistas/usuarios/UsuarioNuevo'

import Clientes from './components/vistas/clientes/Clientes'
import Cliente from './components/vistas/clientes/Cliente'
import ClienteNuevo from './components/vistas/clientes/ClienteNuevo'
import ClienteUpdateMarca from './components/vistas/clientes/ClienteUpdateMarca'
import ClienteUpdateContacto from './components/vistas/clientes/ClienteUpdateContacto'
import ClienteUpdateColores from './components/vistas/clientes/ClienteUpdateColores'
import ClienteUpdateFacturacion from './components/vistas/clientes/ClienteUpdateFacturacion'
import ClienteUpdateRutas from './components/vistas/clientes/ClienteUpdateRutas'
import ClienteUpdateRutasCreate from './components/vistas/clientes/ClienteUpdateRutasCreate'
import CliConfigIndex from './components/vistas/clientes/config/CliConfigIndex'
import CliConfigServicios from './components/vistas/clientes/config/CliConfigServicios'
import CliConfigArticulos from './components/vistas/clientes/config/CliConfigArticulos'
import CliConfigUsuarios from './components/vistas/clientes/config/CliConfigUsuarios'
import CliConfigClientes from './components/vistas/clientes/config/CliConfigClientes'
import CliConfigVentas from './components/vistas/clientes/config/CliConfigVentas'
import ClienteUsuarios from './components/vistas/clientes/usuarios/Usuarios'
import ClienteUsuariosNuevo from './components/vistas/clientes/usuarios/UsuariosNuevo'
import ClienteUsuariosEditar from './components/vistas/clientes/usuarios/UsuariosEditar'
import ClientePagos from './components/vistas/clientes/pagos/ClientePagos'
import ClientePagosNuevo from './components/vistas/clientes/pagos/ClientePagosNuevo'
import ClientePlanModificar from './components/vistas/clientes/pagos/ClientePlanModificar'




import Planes from './components/vistas/planes/Planes'
import PlanNuevo from './components/vistas/planes/PlanNuevo'
import PlanEditar from './components/vistas/planes/PlanEditar'


export default function Router() {



  return (
    <BrowserRouter>
      <Navbar />


      <Routes >

        <Route path="/" element={<Layout />} />
        <Route index element={<Public />} />
        <Route path="login" element={<Login />} />

        <Route element={<RequireAuth />}>
          <Route path="home" element={<Home />} />

          <Route path="/usuarios" element={<Usuarios />} />
          <Route path="/usuarios/nuevo" element={<UsuarioNuevo />} />
          <Route path="/usuarios/:id" element={<UsuarioEditar />} />

          <Route path="/clientes" element={<Clientes />} />
          <Route path="/clientes/nuevo" element={<ClienteNuevo />} />
          <Route path="/clientes/:id" element={<Cliente />} />
          <Route path="/clientes/:id/marca" element={<ClienteUpdateMarca />} />
          <Route path="/clientes/:id/contacto" element={<ClienteUpdateContacto />} />
          <Route path="/clientes/:id/colores" element={<ClienteUpdateColores />} />
          <Route path="/clientes/:id/facturacion" element={<ClienteUpdateFacturacion />} />
          <Route path="/clientes/:id/rutas" element={<ClienteUpdateRutas />} />
          <Route path="/clientes/:id/rutas/:elemento" element={<ClienteUpdateRutasCreate />} />
          <Route path="/clientes/:id/config" element={<CliConfigIndex />} />
          <Route path="/clientes/:id/config/servicios" element={<CliConfigServicios />} />
          <Route path="/clientes/:id/config/articulos" element={<CliConfigArticulos />} />
          <Route path="/clientes/:id/config/usuarios" element={<CliConfigUsuarios />} />
          <Route path="/clientes/:id/config/clientes" element={<CliConfigClientes />} />
          <Route path="/clientes/:id/config/ventas" element={<CliConfigVentas />} />
          <Route path="/clientes/:id/usuarios" element={<ClienteUsuarios />} />
          <Route path="/clientes/:id/usuarios/nuevo" element={<ClienteUsuariosNuevo />} />
          <Route path="/clientes/:idCliente/usuarios/:idUsuario" element={<ClienteUsuariosEditar />} />
          <Route path="/clientes/:id/pagos" element={<ClientePagos />} />
          <Route path="/clientes/:id/pagos/nuevo" element={<ClientePagosNuevo />} />
          <Route path="/clientes/:id/plan-modificar" element={<ClientePlanModificar />} />

          <Route path="/planes" element={<Planes />} />
          <Route path="/planes/nuevo" element={<PlanNuevo />} />
          <Route path="/planes/:id" element={<PlanEditar />} />
        </Route>

      </Routes>



    </BrowserRouter >
  );

}