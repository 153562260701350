import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import SinPermisos from '../../vistas/auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useCreatePlanMutation } from '../../../store/api/endpoints/planesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'
import Select from '../../elementos/Select'

import '../../../assets/css/vistaFormulario.css'

import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import LoopIcon from '@mui/icons-material/Loop'
import CheckIcon from '@mui/icons-material/Check'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import NumbersIcon from '@mui/icons-material/Numbers'

export default function PlanNuevo() {

    const dispatch = useDispatch();
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()

    const [
        createPlan,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreatePlanMutation()


    const [nombre, setNombre] = useState('')
    const [periodo, setPeriodo] = useState('')
    const [cant_productos, setCantProductos] = useState('')
    const [importe, setImporte] = useState('')


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            periodo,
            cant_productos,
            importe,
        }

        createPlan(body)


    }

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el plan!"
            }))

            navigate("/planes")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    if (permisosDelUsuario.indexOf(9) === -1) return <SinPermisos />

    if ( isLoadingCreaRegistro ) return <Loading />

    if (permisosDelUsuario.indexOf(9) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/planes">
                            Planes
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<AutoAwesomeMotionIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<AutoAwesomeMotionIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                label="Período"
                                icon={<LoopIcon />}
                                value={periodo}
                                options={[
                                    {
                                        value: 'month',
                                        label: 'Month'
                                    },
                                    {
                                        value: 'year',
                                        label: 'Year'
                                    }
                                ]}
                                onChange={setPeriodo}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Cant. Productos"
                                icon={<NumbersIcon />}
                                helperText="Cantidad máxima de productos"
                                value={cant_productos}
                                onChange={setCantProductos}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Importe"
                                icon={<AttachMoneyIcon />}
                                helperText="Importe del Plan"
                                value={importe}
                                onChange={setImporte}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}