import { React, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { SketchPicker } from 'react-color'

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import HeaderVista from '../../elementos/HeaderVista'
import SinPermisos from '../../vistas/auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetClienteMutation, useUpdateColoresClienteMutation } from '../../../store/api/endpoints/clientesApiSlice'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import '../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import CheckIcon from '@mui/icons-material/Check'


export default function ClienteUpdateColores() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
        }
    ] = useGetClienteMutation()

    const [
        updateCliente,
        {
            data: responseUpdate,
            isUninitialized: isUninitializedUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdate,
            error: errorDataUpdate
        }
    ] = useUpdateColoresClienteMutation()


    const [menu, setMenu] = useState('')

    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            menu,
        }

        updateCliente({ id: urlParams.id, body })

    }

    useEffect(() => {
        getCliente({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetCliente) {
            setMenu(responseGetCliente.colores.menu)
        }

    }, [isSuccessGetCliente, isErrorGetCliente])

    useEffect(() => {

        if (isSuccessUpdate) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id)

        }

        if (isErrorUpdate) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdate.data.message
            }))
        }

    }, [
        isSuccessUpdate,
        isErrorUpdate
    ])

    const handleChangeMenu = (color, event) => {
        setMenu(color.hex)
    }

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedGetCliente || isLoadingGetCliente || isLoadingUpdate) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseGetCliente.dominio}
                        </Link>
                        <Typography color="text.primary">Colores</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} style={{ paddingBottom: '15px', borderBottom: '1px solid #ccc' }}>
                            <h4 align="center">Menú</h4>
                            <div align="center">
                                <SketchPicker
                                    color={menu}
                                    onChangeComplete={handleChangeMenu}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}