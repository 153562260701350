import { tiendasApi } from '../api'

export const uploadFileApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        uploadFile: builder.mutation({
            query(data){
                const { url, body } = data
                return {
                    url: '/' + url,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removeFile: builder.mutation({
            query(url){
                return {
                    url: url,
                    method: 'DELETE',
                }
            }
        }),
    })
})

export const {
    useUploadFileMutation,
    useRemoveFileMutation,
} = uploadFileApiSlice