import { React, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import SinPermisos from '../../vistas/auth/SinPermisos'

import { useGetPermisosMutation } from '../../../store/api/endpoints/permisosApiSlice'
import { useGetClienteMutation, useGetClienteFacturacionMutation } from '../../../store/api/endpoints/clientesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import DisplayInfoUl from '../../elementos/DisplayInfoUl'
import Divider from '../../elementos/Divider'
import Loading from '../../elementos/Loading'
import ButtonIcon from '../../elementos/ButtonIcon'
import ConfirmDelete from '../../elementos/ConfirmDelete'

import '../../../assets/css/vistaDetalleList.css'

import StoreIcon from '@mui/icons-material/Store'
import PaymentIcon from '@mui/icons-material/Payment'
import SettingsIcon from '@mui/icons-material/Settings'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'

export default function Cliente() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const urlParams = useParams()

    const [
        getPermisos,
        {
            data: responsePermisos,
            isUninitialized: isUninitializedPermisos,
            isLoading: isLoadingPermisos,
            isSuccess: isSuccessPermisos,
            isError: isErrorPermisos,
        }
    ] = useGetPermisosMutation()

    const [
        getCliente,
        {
            data: responseCliente,
            isUninitialized: isUninitializedCliente,
            isLoading: isLoadingCliente,
            isSuccess: isSuccessCliente,
            isError: isErrorCliente,
        }
    ] = useGetClienteMutation()

    const [
        getFacturacion,
        {
            data: responseFacturacion,
            isUninitialized: isUninitializedFacturacion,
            isLoading: isLoadingFacturacion,
            isSuccess: isSuccessFacturacion,
            isError: isErrorFacturacion,
        }
    ] = useGetClienteFacturacionMutation()

    useEffect(() => {
        getPermisos({})
        getCliente({ id: urlParams.id })
        getFacturacion({ id: urlParams.id })
    }, [])


    if (permisosDelUsuario.indexOf(6) === -1) return <SinPermisos />

    if (isUninitializedPermisos || isLoadingPermisos || isUninitializedCliente || isLoadingCliente || isUninitializedFacturacion || isLoadingFacturacion) return <Loading />

    if (permisosDelUsuario.indexOf(6) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Typography color="text.primary">Ficha de {responseCliente.dominio}</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
                end={<>
                    <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="dark"
                        startIcon={<SettingsIcon />}
                        to={"/clientes/" + urlParams.id + "/config"}
                    >
                        Config
                    </ButtonIcon> 
                    <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="secondary"
                        startIcon={<PeopleAltIcon />}
                        to={"/clientes/" + urlParams.id + "/usuarios"}
                    >
                        Usuarios
                    </ButtonIcon> 
                    <ButtonIcon
                        variant="outlined"
                        size="small"
                        color="success"
                        startIcon={<PaymentIcon />}
                        to={"/clientes/" + urlParams.id + "/pagos"}
                    >
                        Pagos
                    </ButtonIcon> 
                    {(permisosDelUsuario.indexOf(8) !== -1 ? <ConfirmDelete
                    httpDelete={"clientes/" + urlParams.id}
                    returnUrl={"/clientes"}
                    variant="outlined"
                    size="small"
                /> : '')}
                </>}
            />
            <Container component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <DisplayInfoUl
                            titulo="Info de Marca"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/marca'
                            } : '')}
                            data={
                                [
                                    { index: 'Nombre', value: responseCliente.marca.marca },
                                    { index: 'Email', value: responseCliente.marca.email },
                                    { index: 'Whatsapp', value: responseCliente.marca.tel_whatsapp },
                                    { index: 'Fijo', value: responseCliente.marca.tel_fijo },
                                    { index: 'Dirección', value: responseCliente.marca.direccion },
                                    { index: 'Mapa', value: responseCliente.marca.mapa },
                                    { index: 'Lenguaje', value: responseCliente.lenguaje },
                                    { index: 'Vendedor', value: responseCliente.vendedor.nombre },
                                    { index: 'Alta', value: responseCliente.alta },
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <DisplayInfoUl
                            titulo="Links"
                            data={
                                [
                                    { index: 'Dominio', value: responseCliente.dominio },
                                    { index: 'Clientes', value: responseCliente.url_clientes },
                                    { index: 'Admin', value: responseCliente.url_admin },
                                ]
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <DisplayInfoUl
                            titulo="Contacto"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/contacto'
                            } : '')}
                            data={
                                [
                                    { index: 'Nombre', value: responseCliente.contacto.nombre },
                                    { index: 'Email', value: responseCliente.contacto.email },
                                    { index: 'Tel Fijo', value: responseCliente.contacto.tel_fijo },
                                    { index: 'Tel Whatsapp', value: responseCliente.contacto.tel_whatsapp },
                                    { index: 'Descripción', value: responseCliente.contacto.descripcion },
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DisplayInfoUl
                            titulo="Color"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/colores'
                            } : '')}
                            data={
                                [
                                    { index: 'Menú', value: responseCliente.colores.menu },
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DisplayInfoUl
                            titulo="Rutas"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/rutas'
                            } : '')}
                            data={
                                [
                                    { index: 'Logo', value: <img width={"100%"} src={responseCliente.rutas.logo} /> },
                                    { index: 'Socials', value: <img width={"100%"} src={responseCliente.rutas.socials} /> },
                                    { index: 'Favicon', value: <img width={"100%"} src={responseCliente.rutas.favicon} /> },
                                    { index: 'CSS', value: (responseCliente.rutas.css ? 'Sí' : '') },
                                ]
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <DisplayInfoUl
                            titulo="Facturación"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/facturacion'
                            } : '')}
                            data={
                                [
                                    { index: 'Activo', value: (responseFacturacion.activo ? 'Sí' : 'No') },
                                    { index: 'Tipo Documento', value: responseFacturacion.tipo_documento },
                                    { index: 'N° Documento', value: responseFacturacion.numero_documento },
                                    { index: 'Condición Iva', value: responseFacturacion.condicion_iva },
                                    { index: 'Razón Social', value: responseFacturacion.razon_social },
                                    { index: 'Condición Venta', value: responseFacturacion.condicion_venta },
                                    { index: 'Domicilio', value: responseFacturacion.domicilio },
                                    { index: 'Provincia', value: responseFacturacion.provincia },
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        
                    </Grid>
                    <Grid item xs={12} md={4}>
                        
                    </Grid>
                </Grid>
            </Container>
        </>
    )

}