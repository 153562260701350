import {  useEffect } from 'react'

import { useGetClienteDominioMutation } from '../../store/api/endpoints/clientesApiSlice'


export default function GetDominioCliente({ idCliente }) {

    const [
        getDominio,
        {
            data: responseGetDominio,
            isUninitialized: isUninitializedGetDominio,
            isLoading: isLoadingGetDominio,
            isError: isSuccessGetDominio,
            error: isErrorGetDominio,
        }
    ] = useGetClienteDominioMutation()


    useEffect(() => {
        getDominio({id: idCliente})
    }, [])

    if (isUninitializedGetDominio || isLoadingGetDominio ) return(<>...</>)

    return (
        <>{responseGetDominio.dominio}</>
    )

}

