import { tiendasApi } from '../api'

export const pagosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPagos: builder.mutation({
            query: (parametros) => ({
                url: '/pagos/' + parametros.idCliente + (parametros.limit ? '/'+parametros.limit : ''),
                method: 'GET',
            })
        }),
        createPago: builder.mutation({
            query: (body) => ({
                url: '/pagos',
                method: 'POST',
                body: body,
            })
        }),
        removePago: builder.mutation({
            query: (id) => ({
                url: '/pagos/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetPagosMutation,
    useCreatePagoMutation,
    useRemovePagoMutation,
} = pagosApiSlice