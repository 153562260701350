import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import Loading from '../../../elementos/Loading'
import SinPermisos from '../../../vistas/auth/SinPermisos'
import SwitchInput from '../../../elementos/SwitchInput'
import { openFeedback } from '../../../../store/slices/feedback'

import { useGetClienteConfigVentasMutation, useUpdateClienteConfigVentasMutation } from '../../../../store/api/endpoints/clientes/clientesConfigVentasApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import GetDominioCliente from '../../../elementos/GetDominioCliente'
import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'
import Divider from '../../../elementos/Divider'

import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import CheckIcon from '@mui/icons-material/Check'

export default function CliConfigVentas() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
        }
    ] = useGetClienteConfigVentasMutation()

    const [
        updateConfig,
        {
            data: responseUpdate,
            isUninitialized: isUninitializedUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdate,
            error: errorDataUpdate
        }
    ] = useUpdateClienteConfigVentasMutation()


    const [pagoPlazoActivo, setPagoPlazoActivo] = useState(false)
    const [pagoPlazoEligeCliente, setPagoPlazoEligeCliente] = useState(false)
    const [pagoMetodoActivo, setPagoMetodoActivo] = useState(false)
    const [pagoMetodoEligeCliente, setPagoMetodoEligeCliente] = useState(false)
    const [envioMetodoActivo, setEnvioMetodoActivo] = useState(false)
    const [envioMetodoEligeCliente, setEnvioMetodoEligeCliente] = useState(false)
    const [cliEditaPedido_minutos, setCliEditaPedidoMinutos] = useState('')
    const [cliEditaPedido_horaDelDiaFecha, setCliEditaPedidoHoraDelDiaFecha] = useState('')
    const [cliEditaPedido_horaDelDiaHs, setCliEditaPedidoHoraDelDiaHs] = useState('')

    const handleFalse = () => {
        return false
    }
    const handleChangeSetPagoPlazoActivo = () => {
        if (pagoPlazoActivo) {
            setPagoPlazoActivo(false)
            setPagoPlazoEligeCliente(false)
        } else {
            setPagoPlazoActivo(true)
        }
    }
    const handleChangeSetPagoMetodoActivo = () => {
        if (pagoMetodoActivo) {
            setPagoMetodoActivo(false)
            setPagoMetodoEligeCliente(false)
        } else {
            setPagoMetodoActivo(true)
        }
    }
    const handleChangeSetEnvioMetodoActivo = () => {
        if (envioMetodoActivo) {
            setEnvioMetodoActivo(false)
            setEnvioMetodoEligeCliente(false)
        } else {
            setEnvioMetodoActivo(true)
        }
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            pago_plazo: {
                activo: pagoPlazoActivo,
                elige_cliente: pagoPlazoEligeCliente,
            },
            pago_metodo: {
                activo: pagoMetodoActivo,
                elige_cliente: pagoMetodoEligeCliente,
            },
            envio_metodo: {
                activo: envioMetodoActivo,
                elige_cliente: envioMetodoEligeCliente,
            },            
            cli_edita_pedido: {
                minutos: cliEditaPedido_minutos,
                hora_del_dia_fecha: cliEditaPedido_horaDelDiaFecha,
                hora_del_dia_hs: cliEditaPedido_horaDelDiaHs,
            },
        }

        updateConfig({ id: urlParams.id, body })

    }

    useEffect(() => {
        getConfig({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetConfig) {
            setPagoPlazoActivo(responseGetConfig.pago_plazo.activo)
            setPagoPlazoEligeCliente(responseGetConfig.pago_plazo.elige_cliente)
            setPagoMetodoActivo(responseGetConfig.pago_metodo.activo)
            setPagoMetodoEligeCliente(responseGetConfig.pago_metodo.elige_cliente)
            setEnvioMetodoActivo(responseGetConfig.envio_metodo.activo)
            setEnvioMetodoEligeCliente(responseGetConfig.envio_metodo.elige_cliente)
            setCliEditaPedidoMinutos(responseGetConfig.cli_edita_pedido?.minutos)
            setCliEditaPedidoHoraDelDiaFecha(responseGetConfig.cli_edita_pedido?.hora_del_dia_fecha)
            setCliEditaPedidoHoraDelDiaHs(responseGetConfig.cli_edita_pedido?.hora_del_dia_hs)
        }

    }, [isSuccessGetConfig, isErrorGetConfig])

    useEffect(() => {

        if (isSuccessUpdate) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id + "/config")

        }

        if (isErrorUpdate) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdate.data.message
            }))
        }

    }, [
        isSuccessUpdate,
        isErrorUpdate
    ])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedGetConfig || isLoadingGetConfig || isLoadingUpdate) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            <GetDominioCliente idCliente={urlParams.id} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/config"}>
                            Config.
                        </Link>
                        <Typography color="text.primary">Ventas</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Plazo de Pago</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={pagoPlazoActivo}
                                onChange={handleChangeSetPagoPlazoActivo}
                                helperText="Indica si las ventas tendrán un plazo de pago."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Elige Cliente"
                                value={pagoPlazoEligeCliente}
                                onChange={(pagoPlazoActivo ? setPagoPlazoEligeCliente : handleFalse)}
                                helperText="Indica si el plazo de pago lo puede elegir el cliente en cada venta o es fijo."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Método de Pago</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={pagoMetodoActivo}
                                onChange={handleChangeSetPagoMetodoActivo}
                                helperText="Indica si las ventas tendrán un método de pago."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Elige Cliente"
                                value={pagoMetodoEligeCliente}
                                onChange={(pagoMetodoActivo ? setPagoMetodoEligeCliente : handleFalse)}
                                helperText="Indica si el método de pago lo puede elegir el cliente en cada venta o es fijo."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Método de Envío</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={envioMetodoActivo}
                                onChange={handleChangeSetEnvioMetodoActivo}
                                helperText="Indica si las ventas tendrán un método de envío."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Elige Cliente"
                                value={envioMetodoEligeCliente}
                                onChange={(envioMetodoActivo ? setEnvioMetodoEligeCliente : handleFalse)}
                                helperText="Indica si el método de envío lo puede elegir el cliente en cada venta o es fijo."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3 style={{ marginBottom: '5px' }}>Cliente Edita Pedido</h3>
                            <small style={{ margin: '0px' }}>El cliente puede modificar el pedido una vez enviado.</small>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <br /><small>Por tiempo luego de enviar el pedido:</small>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Minutos"
                                onChange={setCliEditaPedidoMinutos}
                                value={cliEditaPedido_minutos}
                                helperText="Cantidad de minutos que deben pasar para que no pueda volver a modificarlo. -1 si no se usa."
                                type="text"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>

                        </Grid>
                        <Grid item xs={12} md={4}>

                        </Grid>
                        <Grid item xs={12} >
                            <small>Por horario definido luego de X días o en el mismo día.</small>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Días"
                                onChange={setCliEditaPedidoHoraDelDiaFecha}
                                value={cliEditaPedido_horaDelDiaFecha}
                                helperText="Cuantos días deben pasar desde que hace el pedido. -1 si no se usa."
                                type="text"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Hora del Día"
                                onChange={setCliEditaPedidoHoraDelDiaHs}
                                value={cliEditaPedido_horaDelDiaHs}
                                helperText="HH:mm:ss cuando llega a ese horario ya no puede modificar. -1 si no se usa."
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>

                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}