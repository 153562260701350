import { useParams, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useGetClienteConfigUsuariosMutation } from '../../../../store/api/endpoints/clientes/clientesConfigUsuariosApiSlice'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import SinPermisos from '../../auth/SinPermisos'

import UsuariosTable from './UsuariosTable'


import GetDominioCliente from '../../../elementos/GetDominioCliente'
import ButtonIcon from '../../../elementos/ButtonIcon'
import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'

import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import AddIcon from '@mui/icons-material/Add'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { useEffect } from 'react'

export default function Usuarios() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const urlParams = useParams()

    const [
        getConfigUsuarios,
        {
            data: responseConfigUsuarios,
            isUninitializedConfigUsuarios,
            isLoadingConfigUsuarios,
            isSuccessConfigUsuarios,
            isErrorConfigUsuarios,
        }
    ] = useGetClienteConfigUsuariosMutation()

    useEffect(() => {
        getConfigUsuarios({idCliente: urlParams.id})
    }, [])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedConfigUsuarios || isLoadingConfigUsuarios) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            <GetDominioCliente idCliente={urlParams.id} />
                        </Link>
                        <Typography color="text.primary">Usuarios</Typography>
                    </Breadcrumbs>
                }
                icon={<PeopleAltIcon />}
                end={<ButtonIcon
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon />}
                    to={"/clientes/" + urlParams.id + "/usuarios/nuevo"}
                >
                    Nuevo
                </ButtonIcon>}
            />

            <UsuariosTable
                clienteId={urlParams.id}
                configUsuarios={responseConfigUsuarios}
            />
        </>
    )

}

