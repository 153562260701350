import { tiendasApi } from '../api'

export const vendedoresApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getVendedores: builder.mutation({
            query: (headersTemp) => ({
                url: '/usuarios/vendedores',
                method: 'GET',
                headers: headersTemp,
            })
        }),
    })
})

export const {
    useGetVendedoresMutation
} = vendedoresApiSlice