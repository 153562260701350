import { tiendasApi } from '../api'

export const planesApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getPlanes: builder.mutation({
            query: (headersTemp) => ({
                url: '/planes',
                method: 'GET',
                headers: headersTemp,
            })
        }),
        getPlanesForSelect: builder.mutation({
            query: () => ({
                url: '/planes/all',
                method: 'GET',
            })
        }),
        getPlan: builder.mutation({
            query: (id) => ({
                url: '/planes/' + id,
                method: 'GET',
            })
        }),
        createPlan: builder.mutation({
            query: (body) => ({
                url: '/planes',
                method: 'POST',
                body: body,
            })
        }),
        updatePlan: builder.mutation({
            query(data){
                const { id, body } = data
                return {
                    url: '/planes/' + id,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
        removePlan: builder.mutation({
            query: (id) => ({
                url: '/planes/' + id,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useGetPlanesMutation,
    useGetPlanesForSelectMutation,
    useGetPlanMutation,
    useCreatePlanMutation,
    useUpdatePlanMutation,
    useRemovePlanMutation,
} = planesApiSlice