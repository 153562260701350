import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { setCredentials } from '../../../store/slices/auth'
import { useLoginMutation, useRefreshTokenMutation } from '../../../store/api/endpoints/authApiSlice'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import Input from '../../elementos/Input'
import Loading from '../../elementos/Loading'

import PersonIcon from '@mui/icons-material/Person'
import PasswordIcon from '@mui/icons-material/Password'

import '../../../assets/css/Login.css'

const Login = () => {

    const [email, setEmail] = useState("")
    const [contrasena, setContrasena] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()

    const [login, { isLoading }] = useLoginMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        setErrorMessage("")
    }, [email, contrasena])

    const submitForm = async (e) => {

        e.preventDefault()

        try {
            const userData = await login({ email, contrasena }).unwrap()
            dispatch(setCredentials({ ...userData, email}))
            setEmail("")
            setContrasena("")
            navigate("/home")
        } catch (err) {
            if( !err.status ) setErrorMessage("Ocurrió un error al conectarse al servidor. Por favor, intenta nuevamente.")
            if( err.status === 400 ) setErrorMessage(err.data.message)
        }

    }

    
    

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            {
                                !isLoading &&
                                <form onSubmit={submitForm}>
                                    <h2 style={{ letterSpacing: '1px', fontWeight: '600' }}>¡Benvenuti!</h2>
                                    <Input
                                        fullWidth
                                        type="email"
                                        label="Usuario"
                                        variant="standard"
                                        icon={<PersonIcon />}
                                        restricciones={['email']}
                                        onChange={setEmail}
                                        autoFocus
                                        required
                                    />

                                    <br />
                                    <br />

                                    <Input
                                        fullWidth
                                        type="password"
                                        label="Contraseña"
                                        variant="standard"
                                        icon={<PasswordIcon />}
                                        onChange={setContrasena}
                                        required
                                    />

                                    <br />
                                    <br />

                                    <h3 className="error">{errorMessage ? errorMessage : ''}</h3>
                                    <p><Button type="submit" variant="outlined" color="success">
                                        Ingresar
                                    </Button></p>
                                </form>
                            }
                            {
                                isLoading && <Loading />
                            }
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}

export default Login