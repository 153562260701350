import { useState, useEffect } from 'react'

import Loading from './Loading'
import ButtonIcon from './ButtonIcon'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import { TextField } from '@mui/material'

import '../../assets/css/table.css'

import SearchIcon from '@mui/icons-material/Search'
import CircleIcon from '@mui/icons-material/Circle';


const delay = 0.4

export default function TablaPaginada({
    api,
    thead,
    tbody,
    idCliente,
}) {

    const [page, setPage] = useState(1)
    const [textsearch, setTextsearch] = useState("")

    const [
        getRegistros,
        {
            data: returnedData,
            isUninitialized,
            isLoading,
            isSuccess,
            isError,
        }
    ] = api()

    useEffect(() => {

        let timer1 = setTimeout(() => {

            getRegistros({ idCliente, page, textsearch })

        }, delay * 1000)

        return () => {
            clearTimeout(timer1)
        }

    }, [textsearch, page])

    useEffect(() => {

        getRegistros({ idCliente, page, textsearch })

    }, [])

    if (isLoading || isUninitialized) return (<Loading />)

    return (
        <Container className="containerBigTable" component="main" maxWidth="lg">
            <Grid container>
                <Grid className="titulo" item xs={12} md={8}>
                    <p>Se encontraron {returnedData?.totalItems} registros.</p>
                </Grid>
                <Grid className="buscador" item xs={12} md={4}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                        <TextField onChange={(e) => setTextsearch(e.target.value)} fullWidth label="Buscar..." variant="standard" value={textsearch} />
                    </Box>
                </Grid>
            </Grid>
            <div className="tableContainer">
                <table className="formato1" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            {
                                thead.map((element, index) => (
                                    <th key={index} align={element.align}>{element.label}</th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            returnedData?.totalItems > 0 && returnedData.registros.map((registro, index) => {
                                return (
                                    <tr key={index}>
                                        {
                                            tbody.map((elemento, indexTd) => (
                                                <td key={indexTd} align={thead[indexTd].align}>
                                                    {elemento.tipo === "img" && <img src={registro[elemento.valor]} height="35" />}
                                                    {elemento.tipo === "texto" && registro[elemento.valor]}
                                                    {elemento.tipo === "estado" && <CircleIcon style={{fontSize: '0.9rem' , color:(registro[elemento.valor] ? 'green' : 'red')}} />}
                                                    {elemento.tipo === "botones" && elemento.valor.map((unBoton, indexBoton) => (
                                                        <ButtonIcon
                                                            key={indexBoton}
                                                            variant="outlined"
                                                            size="small"
                                                            color={unBoton.color}
                                                            startIcon={unBoton.icono}
                                                            to={unBoton.ruta + registro.id}
                                                            marginRight
                                                        />
                                                    ))}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                )
                            })
                        }
                        {
                            returnedData?.totalItems === 0 && 
                            <tr><td colSpan={thead.length}>No se encontraron registros.</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            <Pagination
                className="table-pagination"
                showFirstButton showLastButton
                shape="rounded"
                count={returnedData?.pages}
                page={returnedData?.page * 1}
                onChange={(event, value) => setPage(value)}
            />

        </Container>
    )

}