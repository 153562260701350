import { React, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import SinPermisos from '../../../vistas/auth/SinPermisos'

import { useGetClienteDominioMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useCreatePagoMutation } from '../../../../store/api/endpoints/pagosApiSlice'

import { openFeedback } from '../../../../store/slices/feedback'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import Input from '../../../elementos/Input'

import '../../../../assets/css/vistaDetalleList.css'

import PaymentIcon from '@mui/icons-material/Payment'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import CheckIcon from '@mui/icons-material/Check'

export default function ClientePagosNuevo() {

    const dispatch = useDispatch();
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getCliente,
        {
            data: responseCliente,
            isUninitialized: isUninitializedCliente,
            isLoading: isLoadingCliente,
            isSuccess: isSuccessCliente,
            isError: isErrorCliente,
        }
    ] = useGetClienteDominioMutation()

    const [
        createPago,
        {
            data: responseCreaRegistro,
            isUninitialized: isUninitializedCreaRegistro,
            isLoading: isLoadingCreaRegistro,
            isSuccess: isSuccessCreaRegistro,
            isError: isErrorCreaRegistro,
            error: errorDataCreaRegistro
        }
    ] = useCreatePagoMutation()


    useEffect(() => {
        getCliente({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessCreaRegistro) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el plan!"
            }))

            navigate("/clientes/" + urlParams.id + "/pagos")

        }

        if (isErrorCreaRegistro) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaRegistro.data.message
            }))
        }

    }, [
        isSuccessCreaRegistro,
        isErrorCreaRegistro
    ])

    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            cliente: urlParams.id,
            importe,
            modalidad: "Manual",
        }

        createPago(body)


    }

    const [importe, setImporte] = useState('')

    if (permisosDelUsuario.indexOf(13) === -1) return <SinPermisos />

    if (isUninitializedCliente || isLoadingCliente || isLoadingCreaRegistro) return <Loading />

    if (permisosDelUsuario.indexOf(13) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseCliente.dominio}
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/pagos"}>
                            Pagos
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<PaymentIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Importe"
                                icon={<AttachMoneyIcon />}
                                helperText="Importe del Pago"
                                value={importe}
                                onChange={setImporte}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}