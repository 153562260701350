import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import SinPermisos from '../../vistas/auth/SinPermisos'
import Volver from '../../elementos/Volver'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetClienteMutation, useUpdateContactoClienteMutation } from '../../../store/api/endpoints/clientesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'

import HeaderVista from '../../elementos/HeaderVista'
import Dropzone from '../../elementos/Dropzone'
import TituloMini from '../../elementos/TituloMini'

import '../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import CheckIcon from '@mui/icons-material/Check'

export default function ClienteUpdateContacto() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
        }
    ] = useGetClienteMutation()

    const [
        updateContactoCliente,
        {
            data: responseUpdate,
            isUninitialized: isUninitializedUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdate,
            error: errorDataUpdate
        }
    ] = useUpdateContactoClienteMutation()


    const [nombre, setNombre] = useState('')
    const [email, setEmail] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')
    const [tel_fijo, setTelFijo] = useState('ar')
    const [descripcion, setDescripcion] = useState('')

    var formatosPermitidos = "";
    if( ['logo', 'socials', 'banner1', 'banner2'].includes(urlParams.elemento) ){
        formatosPermitidos = 'image/jpeg, image/png';
    }else if( ['favicon'].includes(urlParams.elemento) ){
        formatosPermitidos = 'image/x-icon';
    }else if( ['css'].includes(urlParams.elemento) ){
        formatosPermitidos = 'text/css';
    }

    var maxSize = 0;
    if( ['logo', 'socials', 'css'].includes(urlParams.elemento) ){
        maxSize = 70000;
    }else if( ['banner1', 'banner2', 'favicon'].includes(urlParams.elemento) ){
        maxSize = 200000;
    }

    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            email,
            tel_whatsapp,
            tel_fijo,
            descripcion,
        }

        updateContactoCliente({ id: urlParams.id, body })

    }

    useEffect(() => {
        getCliente({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetCliente) {
            setNombre(responseGetCliente.contacto.nombre)
            setEmail(responseGetCliente.contacto.email)
            setTelWhatsapp(responseGetCliente.contacto.tel_whatsapp)
            setTelFijo(responseGetCliente.contacto.tel_fijo)
            setDescripcion(responseGetCliente.contacto.descripcion)
        }

    }, [isSuccessGetCliente, isErrorGetCliente])

    useEffect(() => {

        if (isSuccessUpdate) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id)

        }

        if (isErrorUpdate) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdate.data.message
            }))
        }

    }, [
        isSuccessUpdate,
        isErrorUpdate
    ])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedGetCliente || isLoadingGetCliente || isLoadingUpdate) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                start={<Volver to={"/clientes/" + urlParams.id + "/rutas"} />}
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseGetCliente.dominio}
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/rutas"}>
                            Rutas
                        </Link>
                        <Typography color="text.primary">Subir Archivo</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <br />
                        <Grid item xs={12} >
                            <TituloMini titulo={"Cargar Archivo"}>Aquí podrás cargar el archivo <b style={{ textTransform: 'uppercase' }}>{urlParams.elemento}</b>.</TituloMini>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Dropzone
                                url={"clientes/" + urlParams.id + "/ruta/" + urlParams.elemento}
                                returnUrl={'/clientes/' + urlParams.id + '/rutas'}
                                formatosPermitidos={formatosPermitidos}
                                maxSize={maxSize}
                                maxFiles={1}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}