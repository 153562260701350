import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import SinPermisos from '../../vistas/auth/SinPermisos'
import TituloMini from '../../elementos/TituloMini'
import SwitchInput from '../../elementos/SwitchInput'
import Select from '../../elementos/Select'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetClienteFacturacionMutation, useUpdateFacturacionClienteMutation } from '../../../store/api/endpoints/clientesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'

import '../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import CheckIcon from '@mui/icons-material/Check'

export default function ClienteUpdateFacturacion() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getFacturacion,
        {
            data: responseGetFacturacion,
            isUninitialized: isUninitializedGetFacturacion,
            isLoading: isLoadingGetFacturacion,
            isSuccess: isSuccessGetFacturacion,
            isError: isErrorGetFacturacion,
        }
    ] = useGetClienteFacturacionMutation()

    const [
        updateCliente,
        {
            data: responseUpdate,
            isUninitialized: isUninitializedUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdate,
            error: errorDataUpdate
        }
    ] = useUpdateFacturacionClienteMutation()


    const [activo, setActivo] = useState(false)
    const [tipo_documento, setTipoDocumento] = useState('')
    const [numero_documento, setNumeroDocumento] = useState('')
    const [condicion_iva, setCondicionIva] = useState('')
    const [razon_social, setRazonSocial] = useState('')
    const [condicion_venta, setCondicionVenta] = useState('')
    const [domicilio, setDomicilio] = useState('')
    const [provincia, setProvincia] = useState('')
    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            activo,
            tipo_documento,
            numero_documento,
            condicion_iva,
            razon_social,
            condicion_venta,
            domicilio,
            provincia,
        }

        updateCliente({ id: urlParams.id, body })

    }

    useEffect(() => {
        getFacturacion({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetFacturacion) {
            setActivo(responseGetFacturacion.activo)
            setTipoDocumento(responseGetFacturacion.tipo_documento)
            setNumeroDocumento(responseGetFacturacion.numero_documento)
            setCondicionIva(responseGetFacturacion.condicion_iva)
            setRazonSocial(responseGetFacturacion.razon_social)
            setCondicionVenta(responseGetFacturacion.condicion_venta)
            setDomicilio(responseGetFacturacion.domicilio)
            setProvincia(responseGetFacturacion.provincia)
        }

    }, [isSuccessGetFacturacion, isErrorGetFacturacion])

    useEffect(() => {

        if (isSuccessUpdate) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id)

        }

        if (isErrorUpdate) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdate.data.message
            }))
        }

    }, [
        isSuccessUpdate,
        isErrorUpdate
    ])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedGetFacturacion || isLoadingGetFacturacion || isLoadingUpdate) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseGetFacturacion.dominio}
                        </Link>
                        <Typography color="text.primary">Facturación</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <br />
                        <Grid item xs={12} >
                            <TituloMini titulo="Facturación">Indica si se le hace factura al cliente cada vez que realiza el pago del plan.</TituloMini>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <SwitchInput
                                label="Activo"
                                value={activo}
                                onChange={setActivo}
                                helperText="¿Se hace factura?"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} ></Grid>
                        <Grid item xs={12} md={3} >
                            <Select
                                label="Tipo Documento"
                                value={tipo_documento}
                                options={[
                                    {
                                        value: '80',
                                        label: 'CUIT'
                                    },
                                    {
                                        value: '86',
                                        label: 'CUIL'
                                    },
                                    {
                                        value: '96',
                                        label: 'DNI'
                                    }
                                ]}
                                onChange={setTipoDocumento}
                                required={activo}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Input
                                label="N° Documento"
                                value={numero_documento}
                                onChange={setNumeroDocumento}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={activo}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Select
                                label="Condición IVA"
                                value={condicion_iva}
                                options={[
                                    {
                                        value: 'IVA Sujeto Exento',
                                        label: 'IVA Sujeto Exento'
                                    },
                                    {
                                        value: 'IVA Responsable Inscripto',
                                        label: 'IVA Responsable Inscripto'
                                    },
                                    {
                                        value: 'Consumidor Final',
                                        label: 'Consumidor Final'
                                    },
                                    {
                                        value: 'Responsable Monotributo',
                                        label: 'Responsable Monotributo'
                                    },
                                    {
                                        value: 'IVA No Alcanzado',
                                        label: 'IVA No Alcanzado'
                                    }
                                ]}
                                onChange={setCondicionIva}
                                required={activo}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Input
                                label="Razón Social"
                                value={razon_social}
                                onChange={setRazonSocial}
                                type="text"
                                required={activo}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Input
                                label="Condición de Venta"
                                value={condicion_venta}
                                onChange={setCondicionVenta}
                                type="text"
                                helperText="Colocar 'Contado'"
                                required={activo}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Input
                                label="Domicilio"
                                value={domicilio}
                                onChange={setDomicilio}
                                type="text"
                                helperText="Calle y Número"
                                required={activo}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Select
                                label="Provincia"
                                value={provincia}
                                options={[
                                    {
                                        value: 'Ciudad de Buenos Aires',
                                        label: 'Ciudad de Buenos Aires'
                                    },
                                    {
                                        value: 'Buenos Aires',
                                        label: 'Buenos Aires'
                                    },
                                    {
                                        value: 'Catamarca',
                                        label: 'Catamarca'
                                    },
                                    {
                                        value: 'Chaco',
                                        label: 'Chaco'
                                    },
                                    {
                                        value: 'Chubut',
                                        label: 'Chubut'
                                    },
                                    {
                                        value: 'Córdoba',
                                        label: 'Córdoba'
                                    },
                                    {
                                        value: 'Corrientes',
                                        label: 'Corrientes'
                                    },
                                    {
                                        value: 'Entre Ríos',
                                        label: 'Entre Ríos'
                                    },
                                    {
                                        value: 'Formosa',
                                        label: 'Formosa'
                                    },
                                    {
                                        value: 'Jujuy',
                                        label: 'Jujuy'
                                    },
                                    {
                                        value: 'La Pampa',
                                        label: 'La Pampa'
                                    },
                                    {
                                        value: 'La Rioja',
                                        label: 'La Rioja'
                                    },
                                    {
                                        value: 'Mendoza',
                                        label: 'Mendoza'
                                    },
                                    {
                                        value: 'Misiones',
                                        label: 'Misiones'
                                    },
                                    {
                                        value: 'Neuquén',
                                        label: 'Neuquén'
                                    },
                                    {
                                        value: 'Río Negro',
                                        label: 'Río Negro'
                                    },
                                    {
                                        value: 'Salta',
                                        label: 'Salta'
                                    },
                                    {
                                        value: 'San Juan',
                                        label: 'San Juan'
                                    },
                                    {
                                        value: 'San Luis',
                                        label: 'San Luis'
                                    },
                                    {
                                        value: 'Santa Cruz',
                                        label: 'Sana Cruz'
                                    },
                                    {
                                        value: 'Santa Fe',
                                        label: 'Santa Fe'
                                    },
                                    {
                                        value: 'Santiago del Estero',
                                        label: 'Santiago del Estero'
                                    },
                                    {
                                        value: 'Tierra del Fuego',
                                        label: 'Tierra del Fuego'
                                    },
                                    {
                                        value: 'Tucumán',
                                        label: 'Tucumán'
                                    }
                                ]}
                                onChange={setProvincia}
                                required={activo}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}