
import { useGetClientesUsuariosMutation } from '../../../../store/api/endpoints/clientes/clientesUsuariosApiSlice'

import TablaPaginada from '../../../elementos/TablaPaginada'

import EditIcon from '@mui/icons-material/Edit'


export default function UsuariosTable({clienteId, configUsuarios}) {

    const thead = []
    thead.push({ label: "Activo", align: "center" })
    if( configUsuarios?.img_principal?.activo ) thead.push({ label: "Imagen", align: "center" })
    thead.push({ label: "Nombre", align: "left" })
    thead.push({ label: "Usuario", align: "left" })
    if( configUsuarios?.posicion?.activo ) thead.push({ label: "Posición", align: "left" })
    thead.push({ label: "Acciones", align: "center" })

    const tbody = []
    tbody.push({ tipo: "estado", valor: "activo" })
    if( configUsuarios?.img_principal?.activo ) tbody.push({ tipo: "img", valor: "img_principal" })
    tbody.push({ tipo: "texto", valor: "nombre" })
    tbody.push({ tipo: "texto", valor: "usuario" })
    if( configUsuarios?.posicion?.activo ) tbody.push({ tipo: "texto", valor: "posicion" })

    let botones = []
    botones.push({ color: "primary", icono: <EditIcon />, ruta: '/clientes/'+clienteId+'/usuarios/' })
    tbody.push({ tipo: "botones", valor: botones })

    return (
        <>
            <TablaPaginada
                api={useGetClientesUsuariosMutation}
                thead={thead}
                tbody={tbody}
                idCliente={clienteId}
            />
        </>
    )

}

