import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import Loading from '../../../elementos/Loading'
import SinPermisos from '../../../vistas/auth/SinPermisos'
import SwitchInput from '../../../elementos/SwitchInput'
import { openFeedback } from '../../../../store/slices/feedback'

import { useGetClienteConfigClientesMutation, useUpdateClienteConfigClientesMutation } from '../../../../store/api/endpoints/clientes/clientesConfigClientesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import GetDominioCliente from '../../../elementos/GetDominioCliente'
import HeaderVista from '../../../elementos/HeaderVista'

import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import CheckIcon from '@mui/icons-material/Check'

export default function CliConfigClientes() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
        }
    ] = useGetClienteConfigClientesMutation()

    const [
        updateConfig,
        {
            data: responseUpdate,
            isUninitialized: isUninitializedUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdate,
            error: errorDataUpdate
        }
    ] = useUpdateClienteConfigClientesMutation()


    const [tipo_docActivo, setTipoDocActivo] = useState(false)
    const [tipo_docRequired, setTipoDocRequired] = useState(false)
    const [documentoActivo, setDocumentoActivo] = useState(false)
    const [documentoRequired, setDocumentoRequired] = useState(false)
    const [razon_socialActivo, setRazonSocialActivo] = useState(false)
    const [razon_socialRequired, setRazonSocialRequired] = useState(false)
    const [direccionActivo, setDireccionActivo] = useState(false)
    const [direccionRequired, setDireccionRequired] = useState(false)
    const [telefonoActivo, setTelefonoActivo] = useState(false)
    const [telefonoRequired, setTelefonoRequired] = useState(false)
    const [whatsappActivo, setWhatsappActivo] = useState(false)
    const [whatsappRequired, setWhatsappRequired] = useState(false)
    const [emailActivo, setEmailActivo] = useState(false)
    const [emailRequired, setEmailRequired] = useState(false)
    const [webActivo, setWebActivo] = useState(false)
    const [webRequired, setWebRequired] = useState(false)
    const [comentariosActivo, setComentariosActivo] = useState(false)
    const [comentariosRequired, setComentariosRequired] = useState(false)
    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            tipo_doc: {
                activo: tipo_docActivo,
                required: (tipo_docActivo ? tipo_docRequired : false),
            },
            documento: {
                activo: documentoActivo,
                required: (documentoActivo ? documentoRequired : false),
            },
            razon_social: {
                activo: razon_socialActivo,
                required: (razon_socialActivo ? razon_socialRequired : false),
            },
            direccion: {
                activo: direccionActivo,
                required: (direccionActivo ? direccionRequired : false),
            },
            telefono: {
                activo: telefonoActivo,
                required: (telefonoActivo ? telefonoRequired : false),
            },
            whatsapp: {
                activo: whatsappActivo,
                required: (whatsappActivo ? whatsappRequired : false),
            },
            email: {
                activo: emailActivo,
                required: (emailActivo ? emailRequired : false),
            },
            web: {
                activo: webActivo,
                required: (webActivo ? webRequired : false),
            },
            comentarios: {
                activo: comentariosActivo,
                required: (comentariosActivo ? comentariosRequired : false),
            },
        }

        updateConfig({ id: urlParams.id, body })

    }

    useEffect(() => {
        getConfig({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetConfig) {
            setTipoDocActivo(responseGetConfig.tipo_doc.activo)
            setTipoDocRequired(responseGetConfig.tipo_doc.required)
            setDocumentoActivo(responseGetConfig.documento.activo)
            setDocumentoRequired(responseGetConfig.documento.required)
            setRazonSocialActivo(responseGetConfig.razon_social.activo)
            setRazonSocialRequired(responseGetConfig.razon_social.required)
            setDireccionActivo(responseGetConfig.direccion.activo)
            setDireccionRequired(responseGetConfig.direccion.required)
            setTelefonoActivo(responseGetConfig.telefono.activo)
            setTelefonoRequired(responseGetConfig.telefono.required)
            setWhatsappActivo(responseGetConfig.whatsapp.activo)
            setWhatsappRequired(responseGetConfig.whatsapp.required)
            setEmailActivo(responseGetConfig.email.activo)
            setEmailRequired(responseGetConfig.email.required)
            setWebActivo(responseGetConfig.web.activo)
            setWebRequired(responseGetConfig.web.required)
            setComentariosActivo(responseGetConfig.comentarios.activo)
            setComentariosRequired(responseGetConfig.comentarios.required)
        }

    }, [isSuccessGetConfig, isErrorGetConfig])

    useEffect(() => {

        if (isSuccessUpdate) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id + "/config")

        }

        if (isErrorUpdate) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdate.data.message
            }))
        }

    }, [
        isSuccessUpdate,
        isErrorUpdate
    ])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedGetConfig || isLoadingGetConfig || isLoadingUpdate) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            <GetDominioCliente idCliente={urlParams.id} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/config"}>
                            Config.
                        </Link>
                        <Typography color="text.primary">Clientes</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Tipo Documento</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={tipo_docActivo}
                                onChange={setTipoDocActivo}
                                helperText="Indica si los clientes tienen tipo de documento."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(tipo_docActivo ? tipo_docRequired : false)}
                                onChange={setTipoDocRequired}
                                helperText="Indica si el tipo de documento es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>N° Documento</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={documentoActivo}
                                onChange={setDocumentoActivo}
                                helperText="Indica si los clientes tienen N° de documento."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(documentoActivo ? documentoRequired : false)}
                                onChange={setDocumentoRequired}
                                helperText="Indica si el documento es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Razón Social</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={razon_socialActivo}
                                onChange={setRazonSocialActivo}
                                helperText="Indica si los clientes tienen razón social."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(razon_socialActivo ? razon_socialRequired : false)}
                                onChange={setRazonSocialRequired}
                                helperText="Indica si la razón social es obligatoria."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Dirección</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={direccionActivo}
                                onChange={setDireccionActivo}
                                helperText="Indica si los clientes tienen dirección."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(direccionActivo ? direccionRequired : false)}
                                onChange={setDireccionRequired}
                                helperText="Indica si la dirección es obligatoria."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Teléfono</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={telefonoActivo}
                                onChange={setTelefonoActivo}
                                helperText="Indica si los clientes tienen teléfono."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(telefonoActivo ? telefonoRequired : false)}
                                onChange={setTelefonoRequired}
                                helperText="Indica si el teléfono es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Whatsapp</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={whatsappActivo}
                                onChange={setWhatsappActivo}
                                helperText="Indica si los clientes tienen whatsapp."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(whatsappActivo ? whatsappRequired : false)}
                                onChange={setWhatsappRequired}
                                helperText="Indica si el whatsapp es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Email</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={emailActivo}
                                onChange={setEmailActivo}
                                helperText="Indica si los clientes tienen email."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(emailActivo ? emailRequired : false)}
                                onChange={setEmailRequired}
                                helperText="Indica si el email es obligatorio."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Web</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={webActivo}
                                onChange={setWebActivo}
                                helperText="Indica si los clientes tienen web."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(webActivo ? webRequired : false)}
                                onChange={setWebRequired}
                                helperText="Indica si la web es obligatoria."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Comentarios</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Activo"
                                value={comentariosActivo}
                                onChange={setComentariosActivo}
                                helperText="Indica si los clientes tienen comentarios."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Obligatorio"
                                value={(comentariosActivo ? comentariosRequired : false)}
                                onChange={setComentariosRequired}
                                helperText="Indica si los comentarios son obligatorios."
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}