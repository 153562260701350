import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import SinPermisos from '../../../vistas/auth/SinPermisos'

import { openFeedback } from '../../../../store/slices/feedback'

import { useGetClienteDominioMutation, useGetClientePlanMutation, useUpdatePlanMutation } from '../../../../store/api/endpoints/clientesApiSlice'
import { useGetPlanesForSelectMutation } from '../../../../store/api/endpoints/planesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import Loading from '../../../elementos/Loading'
import Select from '../../../elementos/Select'

import '../../../../assets/css/vistaDetalleList.css'

import PaymentIcon from '@mui/icons-material/Payment'
import CreateIcon from '@mui/icons-material/Create'
import CheckIcon from '@mui/icons-material/Check'

export default function ClientePlanModificar() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const urlParams = useParams()


    const [
        getCliente,
        {
            data: responseCliente,
            isUninitialized: isUninitializedCliente,
            isLoading: isLoadingCliente,
            isSuccess: isSuccessCliente,
            isError: isErrorCliente,
        }
    ] = useGetClienteDominioMutation()

    const [
        getPlanActual,
        {
            data: responsePlanActual,
            isUninitialized: isUninitializedPlanActual,
            isLoading: isLoadingPlanActual,
            isSuccess: isSuccessPlanActual,
            isError: isErrorPlanActual,
        }
    ] = useGetClientePlanMutation()

    const [
        getPlanes,
        {
            data: responsePlanes,
            isUninitialized: isUninitializedPlanes,
            isLoading: isLoadingPlanes,
            isSuccess: isSuccessPlanes,
            isError: isErrorPlanes,
        }
    ] = useGetPlanesForSelectMutation()

    const [
        updatePlan,
        {
            data: responseUpdatePlan,
            isUninitialized: isUninitializedUpdatePlan,
            isLoading: isLoadingUpdatePlan,
            isSuccess: isSuccessUpdatePlan,
            isError: isErrorUpdatePlan,
            error: errorDataUpdatePlan,
        }
    ] = useUpdatePlanMutation()

    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            plan: plan,
        }

        updatePlan({id: urlParams.id, body: body})

    }

    useEffect(() => {
        getCliente({ id: urlParams.id })
        getPlanes()
        getPlanActual({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessPlanActual && responsePlanActual.id) setPlan(responsePlanActual.id)

    }, [isSuccessPlanActual, isErrorPlanActual])

    useEffect(() => {

        if (isSuccessUpdatePlan) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id + "/pagos")

        }

        if (isErrorUpdatePlan) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdatePlan.data.message
            }))
        }

    }, [
        isSuccessUpdatePlan,
        isErrorUpdatePlan
    ])

    const [plan, setPlan] = useState('')

    if (permisosDelUsuario.indexOf(13) === -1) return <SinPermisos />

    if (isUninitializedCliente || isUninitializedPlanActual || isUninitializedPlanes || isLoadingPlanActual || isLoadingPlanes || isLoadingCliente || isLoadingUpdatePlan) return <Loading />

    if (permisosDelUsuario.indexOf(13) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseCliente.dominio}
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/pagos"}>
                            Pagos
                        </Link>
                        <Typography color="text.primary">Cambiar Plan</Typography>
                    </Breadcrumbs>
                }
                icon={<CreateIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}></Grid>
                        <Grid item xs={12} md={6}>
                            <Select
                                label="Plan"
                                icon={<PaymentIcon />}
                                value={plan}
                                options={responsePlanes.map(function (e) {
                                    return {
                                        value: e._id,
                                        label: e.nombre
                                    }
                                })}
                                onChange={setPlan}
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}