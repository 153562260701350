import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import SinPermisos from '../../vistas/auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetPermisosMutation } from '../../../store/api/endpoints/permisosApiSlice'
import { useGetUsuarioMutation, useUpdateUsuarioMutation } from '../../../store/api/endpoints/usuariosApiSlice'
import { useGetVendedoresMutation } from '../../../store/api/endpoints/vendedoresApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'
import CheckboxList from '../../elementos/CheckboxList'
import ConfirmDelete from '../../elementos/ConfirmDelete'

import '../../../assets/css/vistaFormulario.css'

import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PercentIcon from '@mui/icons-material/Percent'
import PasswordIcon from '@mui/icons-material/Password'
import CheckIcon from '@mui/icons-material/Check'

export default function UsuarioEditar() {

    const dispatch = useDispatch();
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getPermisos,
        {
            data: responsePermisos,
            isUninitialized: isUninitializedPermisos,
            isLoading: isLoadingPermisos,
            isSuccess: isSuccessPermisos,
            isError: isErrorPermisos,
        }
    ] = useGetPermisosMutation()

    const [
        getVendedores,
        {
            data: responseVendedores,
            isUninitialized: isUninitializedVendedores,
            isLoading: isLoadingVendedores,
            isSuccess: isSuccessVendedores,
            isError: isErrorVendedores,
        }
    ] = useGetVendedoresMutation()

    const [
        getUsuario,
        {
            data: responseGetUsuario,
            isUninitialized: isUninitializedGetUsuario,
            isLoading: isLoadingGetUsuario,
            isSuccess: isSuccessGetUsuario,
            isError: isErrorGetUsuario,
        }
    ] = useGetUsuarioMutation()

    const [
        updateUsuario,
        {
            data: responseUpdateUsuario,
            isUninitialized: isUninitializedUpdateUsuario,
            isLoading: isLoadingUpdateUsuario,
            isSuccess: isSuccessUpdateUsuario,
            isError: isErrorUpdateUsuario,
            error: errorDataUpdateUsuario
        }
    ] = useUpdateUsuarioMutation()


    const [nombre, setNombre] = useState('')
    const [email, setEmail] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')
    const [comision, setComision] = useState('')
    const [contrasena1, setContrasena1] = useState('')
    const [contrasena2, setContrasena2] = useState('')
    const [permisos, setPermisos] = useState([])
    const [acceso_vendedores, setAccesoVendedores] = useState([])




    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            email,
            tel_whatsapp,
            comision,
            contrasena1,
            contrasena2,
            permisos,
            acceso_vendedores,
        }

        updateUsuario({ id: urlParams.id, body })


    }

    useEffect(() => {

        getPermisos({})
        getUsuario(urlParams.id)
        getVendedores()

    }, [])

    useEffect(() => {

        if (isSuccessGetUsuario) {
            setEmail(responseGetUsuario.email)
            setNombre(responseGetUsuario.nombre)
            setTelWhatsapp(responseGetUsuario.tel_whatsapp)
            setComision(responseGetUsuario.comision)
            setPermisos(responseGetUsuario.permisos)
            setAccesoVendedores(responseGetUsuario.acceso_vendedores)
        }

    }, [isSuccessGetUsuario, isErrorGetUsuario])

    useEffect(() => {

        if (isSuccessUpdateUsuario) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el usuario!"
            }))

            navigate("/usuarios")

        }

        if (isErrorUpdateUsuario) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdateUsuario.data.message
            }))
        }

    }, [
        isSuccessUpdateUsuario,
        isErrorUpdateUsuario,
    ])

    if (permisosDelUsuario.indexOf(2) === -1) return <SinPermisos />

    if (isUninitializedPermisos || isLoadingPermisos || isLoadingUpdateUsuario || isLoadingGetUsuario || isUninitializedVendedores || isLoadingVendedores || isUninitializedGetUsuario) return <Loading />

    if (permisosDelUsuario.indexOf(2) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/usuarios">
                            Usuarios
                        </Link>
                        <Typography color="text.primary">Modificar</Typography>
                    </Breadcrumbs>
                }
                icon={<SupportAgentIcon />}
                end={(permisosDelUsuario.indexOf(9) !== -1 ? <ConfirmDelete
                    httpDelete={"usuarios/" + urlParams.id}
                    returnUrl={"/usuarios"}
                /> : '')}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<AccountCircle />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Email"
                                icon={<MailOutlineIcon />}
                                value={email}
                                onChange={setEmail}
                                type="mail"
                                restricciones={['email']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Whatsapp"
                                icon={<WhatsAppIcon />}
                                helperText="Ej: 5491112341234"
                                value={tel_whatsapp}
                                onChange={setTelWhatsapp}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Comisión"
                                icon={<PercentIcon />}
                                helperText="Ej: 0.1"
                                value={comision}
                                onChange={setComision}
                                type="tel"
                                restricciones={['soloNumerosDecimales']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Contraseña"
                                icon={<PasswordIcon />}
                                value={contrasena1}
                                onChange={setContrasena1}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Repetir Contraseña"
                                icon={<PasswordIcon />}
                                value={contrasena2}
                                onChange={setContrasena2}
                                type="password"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CheckboxList
                                titulo="Permisos"
                                opciones={responsePermisos}
                                elegidos={permisos}
                                handleChange={setPermisos}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CheckboxList
                                titulo="Acceso Vendedores"
                                opciones={responseVendedores.map(elem => { return { codigo: elem._id, nombre: elem.nombre } })}
                                elegidos={acceso_vendedores}
                                handleChange={setAccesoVendedores}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}