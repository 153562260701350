import { React, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import SinPermisos from '../../auth/SinPermisos'

import {
    useGetConfigServiciosMutation,
    useGetConfigArticulosMutation,
    useGetClienteDominioMutation,
} from '../../../../store/api/endpoints/clientesApiSlice'

import {
    useGetClienteConfigUsuariosMutation,
} from '../../../../store/api/endpoints/clientes/clientesConfigUsuariosApiSlice'

import {
    useGetClienteConfigClientesMutation,
} from '../../../../store/api/endpoints/clientes/clientesConfigClientesApiSlice'

import {
    useGetClienteConfigVentasMutation,
} from '../../../../store/api/endpoints/clientes/clientesConfigVentasApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../../elementos/HeaderVista'
import DisplayInfoUl from '../../../elementos/DisplayInfoUl'
import Divider from '../../../elementos/Divider'
import Loading from '../../../elementos/Loading'

import '../../../../assets/css/vistaDetalleList.css'

import SettingsIcon from '@mui/icons-material/Settings'

export default function CliConfigIndex() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const urlParams = useParams()

    const [
        getCliente,
        {
            data: responseCliente,
            isUninitialized: isUninitializedCliente,
            isLoading: isLoadingCliente,
            isSuccess: isSuccessCliente,
            isError: isErrorCliente,
        }
    ] = useGetClienteDominioMutation()

    const [
        getServicios,
        {
            data: responseServicios,
            isUninitialized: isUninitializedServicios,
            isLoading: isLoadingServicios,
            isSuccess: isSuccessServicios,
            isError: isErrorServicios,
        }
    ] = useGetConfigServiciosMutation()

    const [
        getArticulos,
        {
            data: responseArticulos,
            isUninitialized: isUninitializedArticulos,
            isLoading: isLoadingArticulos,
            isSuccess: isSuccessArticulos,
            isError: isErrorArticulos,
        }
    ] = useGetConfigArticulosMutation()

    const [
        getUsuarios,
        {
            data: responseUsuarios,
            isUninitialized: isUninitializedUsuarios,
            isLoading: isLoadingUsuarios,
            isSuccess: isSuccessUsuarios,
            isError: isErrorUsuarios,
        }
    ] = useGetClienteConfigUsuariosMutation()

    const [
        getConfigCliente,
        {
            data: responseConfigCliente,
            isUninitialized: isUninitializedConfigCliente,
            isLoading: isLoadingConfigCliente,
            isSuccess: isSuccessConfigCliente,
            isError: isErrorConfigCliente,
        }
    ] = useGetClienteConfigClientesMutation()

    const [
        getConfigVentas,
        {
            data: responseConfigVentas,
            isUninitialized: isUninitializedConfigVentas,
            isLoading: isLoadingConfigVentas,
            isSuccess: isSuccessConfigVentas,
            isError: isErrorConfigVentas,
        }
    ] = useGetClienteConfigVentasMutation()

    useEffect(() => {
        getCliente({ id: urlParams.id })
        getServicios({ id: urlParams.id })
        getArticulos({ id: urlParams.id })
        getUsuarios({ idCliente: urlParams.id })
        getConfigCliente({ id: urlParams.id })
        getConfigVentas({ id: urlParams.id })
    }, [])


    if (permisosDelUsuario.indexOf(6) === -1) return <SinPermisos />

    if (
        isUninitializedCliente ||
        isLoadingCliente ||
        isUninitializedServicios ||
        isLoadingServicios ||
        isUninitializedArticulos ||
        isLoadingArticulos ||
        isUninitializedUsuarios ||
        isLoadingUsuarios ||
        isUninitializedConfigCliente ||
        isLoadingConfigCliente ||
        isUninitializedConfigVentas ||
        isLoadingConfigVentas
    ) return <Loading />

    if (permisosDelUsuario.indexOf(6) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseCliente.dominio}
                        </Link>
                        <Typography color="text.primary">Config</Typography>
                    </Breadcrumbs>
                }
                icon={<SettingsIcon />}
            />
            <Container component="main" maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DisplayInfoUl
                            titulo="Servicios"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/config/servicios'
                            } : '')}
                            data={
                                [
                                    { index: 'Activo', value: (responseServicios.activo ? 'Sí' : 'No') },
                                    { index: 'Categorías', value: (responseServicios.categorias?.activo ? 'Sí' : 'No') },
                                    { index: 'Subcategorías', value: (responseServicios.subcategorias?.activo ? 'Sí' : 'No') },
                                    { index: 'Precio', value: (responseServicios.precio?.activo ? 'Sí' : 'No') },
                                    { index: 'Descripción', value: (responseServicios.descripcion?.activo ? 'Sí' : 'No') },
                                    { index: 'Img Principal', value: (responseServicios.img_principal?.activo ? 'Sí' : 'No') },
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DisplayInfoUl
                            titulo="Articulos"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/config/articulos'
                            } : '')}
                            data={
                                [
                                    { index: 'Activo', value: (responseArticulos.activo ? 'Sí' : 'No') },
                                    { index: 'Categorías', value: (responseArticulos.categorias?.activo ? 'Sí' : 'No') },
                                    { index: 'Subcategorías', value: (responseArticulos.subcategorias?.activo ? 'Sí' : 'No') },
                                    { index: 'Código', value: (responseArticulos.codigo?.activo ? 'Sí' : 'No') },
                                    { index: 'Precio', value: (responseArticulos.precio?.activo ? 'Sí' : 'No') },
                                    { index: 'Descripción', value: (responseArticulos.descripcion?.activo ? 'Sí' : 'No') },
                                    { index: 'Img Principal', value: (responseArticulos.img_principal?.activo ? 'Sí' : 'No') },
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DisplayInfoUl
                            titulo="Usuarios"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/config/usuarios'
                            } : '')}
                            data={
                                [
                                    { index: 'Tel. Fijo', value: (responseUsuarios.tel_fijo?.activo ? 'Sí' : 'No') },
                                    { index: 'Whatsapp', value: (responseUsuarios.whatsapp?.activo ? 'Sí' : 'No') },
                                    { index: 'Email', value: (responseUsuarios.email?.activo ? 'Sí' : 'No') },
                                    { index: 'Posición', value: (responseUsuarios.posicion?.activo ? 'Sí' : 'No') },
                                    { index: 'Img. Principal', value: (responseUsuarios.img_principal?.activo ? 'Sí' : 'No') },
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DisplayInfoUl
                            titulo="Clientes"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/config/clientes'
                            } : '')}
                            data={
                                [
                                    { index: 'Tipo Doc.', value: (responseConfigCliente.tipo_doc.activo ? 'Sí' : 'No') },
                                    { index: 'Documento', value: (responseConfigCliente.documento.activo ? 'Sí' : 'No') },
                                    { index: 'Razón Social', value: (responseConfigCliente.razon_social.activo ? 'Sí' : 'No') },
                                    { index: 'Dirección', value: (responseConfigCliente.direccion.activo ? 'Sí' : 'No') },
                                    { index: 'Teléfono', value: (responseConfigCliente.telefono.activo ? 'Sí' : 'No') },
                                    { index: 'Whatsapp', value: (responseConfigCliente.whatsapp.activo ? 'Sí' : 'No') },
                                    { index: 'Email', value: (responseConfigCliente.email.activo ? 'Sí' : 'No') },
                                    { index: 'Web', value: (responseConfigCliente.web.activo ? 'Sí' : 'No') },
                                    { index: 'Comentarios', value: (responseConfigCliente.comentarios.activo ? 'Sí' : 'No') },
                                ]
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DisplayInfoUl
                            titulo="Ventas"
                            editar={(permisosDelUsuario.indexOf(7) !== -1 ? {
                                path: '/clientes/' + urlParams.id + '/config/ventas'
                            } : '')}
                            data={
                                [
                                    { index: 'Pago Plazo (activo)', value: (responseConfigVentas.pago_plazo.activo ? 'Sí' : 'No') },
                                    { index: 'Pago Plazo (elige cliente)', value: (responseConfigVentas.pago_plazo.elige_cliente ? 'Sí' : 'No') },
                                    { index: 'Pago Método (activo)', value: (responseConfigVentas.pago_metodo.activo ? 'Sí' : 'No') },
                                    { index: 'Pago Método (elige cliente)', value: (responseConfigVentas.pago_metodo.elige_cliente ? 'Sí' : 'No') },
                                    { index: 'Envío Método (activo)', value: (responseConfigVentas.envio_metodo.activo ? 'Sí' : 'No') },
                                    { index: 'Envío Método (elige cliente)', value: (responseConfigVentas.envio_metodo.elige_cliente ? 'Sí' : 'No') },
                                    { index: 'Cliente Edita Pedido - Minutos', value: (responseConfigVentas.cli_edita_pedido.minutos != -1 ? responseConfigVentas.cli_edita_pedido.minutos : 'No') },
                                    { index: 'Cliente Edita Pedido - Hora del día fecha', value: (responseConfigVentas.cli_edita_pedido.hora_del_dia_fecha != -1 ? responseConfigVentas.cli_edita_pedido.hora_del_dia_fecha : 'No') },
                                    { index: 'Cliente Edita Pedido - Hora del día horario', value: (responseConfigVentas.cli_edita_pedido.hora_del_dia_hs != -1 ? responseConfigVentas.cli_edita_pedido.hora_del_dia_hs : 'No') },
                                ]
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>

                    </Grid>
                    <Grid item xs={12} md={4}>

                    </Grid>
                    <Grid item xs={12} md={4}>

                    </Grid>
                </Grid>
            </Container>
        </>
    )

}