import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../../store/slices/auth'
import Loading from '../../../elementos/Loading'
import SinPermisos from '../../../vistas/auth/SinPermisos'
import SwitchInput from '../../../elementos/SwitchInput'
import { openFeedback } from '../../../../store/slices/feedback'

import { useGetClienteConfigUsuariosMutation, useUpdateClienteConfigUsuariosMutation } from '../../../../store/api/endpoints/clientes/clientesConfigUsuariosApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import GetDominioCliente from '../../../elementos/GetDominioCliente'
import HeaderVista from '../../../elementos/HeaderVista'
import Input from '../../../elementos/Input'

import Button from '@mui/material/Button'

import '../../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import CheckIcon from '@mui/icons-material/Check'

export default function CliConfigUsuarios() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getConfig,
        {
            data: responseGetConfig,
            isUninitialized: isUninitializedGetConfig,
            isLoading: isLoadingGetConfig,
            isSuccess: isSuccessGetConfig,
            isError: isErrorGetConfig,
        }
    ] = useGetClienteConfigUsuariosMutation()

    const [
        updateConfig,
        {
            data: responseUpdate,
            isUninitialized: isUninitializedUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdate,
            error: errorDataUpdate
        }
    ] = useUpdateClienteConfigUsuariosMutation()


    const [img_principalActivo, setImgPrincipalActivo] = useState(false)
    const [img_principalRequired, setImgPrincipalRequired] = useState(false)
    const [img_principalHeight, setImgPrincipalHeight] = useState(0)
    const [img_principalWidth, setImgPrincipalWidth] = useState(0)

    const [posicionActivo, setPosicionActivo] = useState(false)
    const [posicionRequired, setPosicionRequired] = useState(false)

    const [emailActivo, setEmailActivo] = useState(false)
    const [emailRequired, setEmailRequired] = useState(false)

    const [telWhatsappActivo, setWhatsappActivo] = useState(false)
    const [telWhatsappRequired, setWhatsappRequired] = useState(false)

    const [telFijoActivo, setFijoActivo] = useState(false)
    const [telFijoRequired, setFijoRequired] = useState(false)

    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            img_principal: {
                activo: img_principalActivo,
                required: img_principalRequired,
                height: img_principalHeight,
                width: img_principalWidth,
            },
            posicion: {
                activo: posicionActivo,
                required: posicionRequired,
            },
            email: {
                activo: emailActivo,
                required: emailRequired,
            },
            tel_whatsapp: {
                activo: telWhatsappActivo,
                required: telWhatsappRequired,
            },
            tel_fijo: {
                activo: telFijoActivo,
                required: telFijoRequired,
            },
        }

        updateConfig({ id: urlParams.id, body })

    }

    useEffect(() => {
        getConfig({ idCliente: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetConfig) {
            if (responseGetConfig.img_principal?.activo) setImgPrincipalActivo(responseGetConfig.img_principal.activo)
            if (responseGetConfig.img_principal?.activo) setImgPrincipalRequired(responseGetConfig.img_principal.required)
            if (responseGetConfig.img_principal?.activo) setImgPrincipalHeight(responseGetConfig.img_principal.height)
            if (responseGetConfig.img_principal?.activo) setImgPrincipalWidth(responseGetConfig.img_principal.width)
            if (responseGetConfig.posicion?.activo) setPosicionActivo(responseGetConfig.posicion.activo)
            if (responseGetConfig.posicion?.activo) setPosicionRequired(responseGetConfig.posicion.required)
            if (responseGetConfig.email?.activo) setEmailActivo(responseGetConfig.email.activo)
            if (responseGetConfig.email?.activo) setEmailRequired(responseGetConfig.email.required)
            if (responseGetConfig.tel_whatsapp?.activo) setWhatsappActivo(responseGetConfig.tel_whatsapp.activo)
            if (responseGetConfig.tel_whatsapp?.activo) setWhatsappRequired(responseGetConfig.tel_whatsapp.required)
            if (responseGetConfig.tel_fijo?.activo) setFijoActivo(responseGetConfig.tel_fijo.activo)
            if (responseGetConfig.tel_fijo?.activo) setFijoRequired(responseGetConfig.tel_fijo.required)
        }

    }, [isSuccessGetConfig, isErrorGetConfig])

    useEffect(() => {

        if (isSuccessUpdate) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id + "/config")

        }

        if (isErrorUpdate) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdate.data.message
            }))
        }

    }, [
        isSuccessUpdate,
        isErrorUpdate
    ])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if (isUninitializedGetConfig || isLoadingGetConfig || isLoadingUpdate) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            <GetDominioCliente idCliente={urlParams.id} />
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id + "/config"}>
                            Config.
                        </Link>
                        <Typography color="text.primary">Usuarios</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Imágen de Perfil</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Imágen Principal"
                                onChange={setImgPrincipalActivo}
                                value={img_principalActivo}
                                helperText="Indica si los servicios tienen imágen principal."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Ancho"
                                onChange={setImgPrincipalWidth}
                                value={img_principalWidth}
                                helperText="Ancho de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={img_principalActivo}
                                disabled={!img_principalActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Imágen Alto"
                                onChange={setImgPrincipalHeight}
                                value={img_principalHeight}
                                helperText="Alto de la imágen principal."
                                type="tel"
                                restricciones={['soloNumeros']}
                                required={img_principalActivo}
                                disabled={!img_principalActivo}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Posición</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Posición Activo"
                                onChange={setPosicionActivo}
                                value={posicionActivo}
                                helperText="Indica si los usuarios tienen una posición (puesto de trabajo)."
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Posición Obligatoria"
                                onChange={setPosicionRequired}
                                value={(posicionActivo ? posicionRequired : false)}
                                helperText="Indica si la posición es obligatoria."
                                disabled={!posicionActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Email</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Email Activo"
                                onChange={setEmailActivo}
                                value={emailActivo}
                                helperText="Indica si los usuarios tienen email"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Email Obligatorio"
                                onChange={setEmailRequired}
                                value={(emailActivo ? emailRequired : false)}
                                helperText="Indica si el email es obligatorio."
                                disabled={!emailActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Whatsapp</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Whatsapp Activo"
                                onChange={setWhatsappActivo}
                                value={telWhatsappActivo}
                                helperText="Indica si los usuarios tienen whatsapp"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Whatsapp Obligatorio"
                                onChange={setWhatsappRequired}
                                value={(telWhatsappActivo ? telWhatsappRequired : false)}
                                helperText="Indica si el whatsapp es obligatorio."
                                disabled={!telWhatsappActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <h3>Teléfono Fijo</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Tel Fijo Activo"
                                onChange={setFijoActivo}
                                value={telFijoActivo}
                                helperText="Indica si los usuarios tienen teléfono fijo"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <SwitchInput
                                label="Tel Fijo Obligatorio"
                                onChange={setFijoRequired}
                                value={(telFijoActivo ? telFijoRequired : false)}
                                helperText="Indica si el teléfono fijo es obligatorio."
                                disabled={!telFijoActivo}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}