import { tiendasApi } from '../../api'

export const clientesUsuariosApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getClientesUsuarios: builder.mutation({
            query: ({idCliente, headersTemp}) => {
                return {
                    url: '/clientes/' + idCliente + '/usuarios',
                    method: 'GET',
                    headers: headersTemp,
                }
            }
        }),
        getClientesUsuario: builder.mutation({
            query: ({idCliente, idUsuario}) => {
                return {
                    url: '/clientes/' + idCliente + '/usuarios/' + idUsuario,
                    method: 'GET',
                }
            }
        }),
        getTodosLosPermisos: builder.mutation({
            query: () => {
                return {
                    url: '/clientes/todos-los-permisos',
                    method: 'GET',
                }
            }
        }),
        createClientesUsuario: builder.mutation({
            query: ({idCliente, body}) => ({
                url: '/clientes/' + idCliente + '/usuarios',
                method: 'POST',
                body: body,
            })
        }),
        updateClientesUsuario: builder.mutation({
            query: ({idCliente, idUsuario, body}) => ({
                url: '/clientes/' + idCliente + '/usuarios/' + idUsuario,
                method: 'PUT',
                body: body,
            })
        }),
    })
})

export const {
    useGetClientesUsuariosMutation,
    useGetClientesUsuarioMutation,
    useGetTodosLosPermisosMutation,
    useCreateClientesUsuarioMutation,
    useUpdateClientesUsuarioMutation,

} = clientesUsuariosApiSlice