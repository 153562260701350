import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useSelector } from "react-redux"
import { getUsuarioToken } from "../../../store/slices/auth"
import Sidebar from "../Sidebar"
import { getStatusSidebar } from "../../../store/slices/sidebar"

const RequireAuth = () => {
    
    const token = useSelector(getUsuarioToken)
    const location = useLocation()

    const statusSidebar = useSelector(getStatusSidebar)

    return (
        token
            ? <><Sidebar /><div id="routesContainer" className={(!statusSidebar ? 'extendido' : '')}><div id="contenedor"><Outlet /></div></div></>
            : <Navigate to="/" state={{ from: location }} replace />
    )
}

export default RequireAuth