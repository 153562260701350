import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import SinPermisos from '../../vistas/auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetClienteMutation, useUpdateContactoClienteMutation } from '../../../store/api/endpoints/clientesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'

import '../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CheckIcon from '@mui/icons-material/Check'
import PersonIcon from '@mui/icons-material/Person'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import CommentIcon from '@mui/icons-material/Comment'

export default function ClienteUpdateContacto() {

    const dispatch = useDispatch()
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()
    const urlParams = useParams()

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
        }
    ] = useGetClienteMutation()

    const [
        updateContactoCliente,
        {
            data: responseUpdate,
            isUninitialized: isUninitializedUpdate,
            isLoading: isLoadingUpdate,
            isSuccess: isSuccessUpdate,
            isError: isErrorUpdate,
            error: errorDataUpdate
        }
    ] = useUpdateContactoClienteMutation()


    const [nombre, setNombre] = useState('')
    const [email, setEmail] = useState('')
    const [tel_whatsapp, setTelWhatsapp] = useState('')
    const [tel_fijo, setTelFijo] = useState('ar')
    const [descripcion, setDescripcion] = useState('')
    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            email,
            tel_whatsapp,
            tel_fijo,
            descripcion,
        }

        updateContactoCliente({id: urlParams.id, body})

    }

    useEffect(() => {
        getCliente({ id: urlParams.id })
    }, [])

    useEffect(() => {

        if (isSuccessGetCliente) {
            setNombre(responseGetCliente.contacto.nombre)
            setEmail(responseGetCliente.contacto.email)
            setTelWhatsapp(responseGetCliente.contacto.tel_whatsapp)
            setTelFijo(responseGetCliente.contacto.tel_fijo)
            setDescripcion(responseGetCliente.contacto.descripcion)
        }

    }, [isSuccessGetCliente, isErrorGetCliente])

    useEffect(() => {

        if (isSuccessUpdate) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha actualizado el cliente!"
            }))

            navigate("/clientes/" + urlParams.id)

        }

        if (isErrorUpdate) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataUpdate.data.message
            }))
        }

    }, [
        isSuccessUpdate,
        isErrorUpdate
    ])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if ( isUninitializedGetCliente || isLoadingGetCliente || isLoadingUpdate) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseGetCliente.dominio}
                        </Link>
                        <Typography color="text.primary">Contacto</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<PersonIcon />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Email"
                                icon={<MailOutlineIcon />}
                                value={email}
                                onChange={setEmail}
                                type="mail"
                                restricciones={['email']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Whatsapp"
                                icon={<WhatsAppIcon />}
                                helperText="Ej: 5491112341234"
                                value={tel_whatsapp}
                                onChange={setTelWhatsapp}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Tel Fijo"
                                icon={<PhoneEnabledIcon />}
                                helperText="Ej: 5491112341234"
                                value={tel_fijo}
                                onChange={setTelFijo}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Descripción"
                                icon={<CommentIcon />}
                                value={descripcion}
                                onChange={setDescripcion}
                                type="text"
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}