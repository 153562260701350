import { React, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import SinPermisos from '../../vistas/auth/SinPermisos'
import Card from '../../elementos/Card'

import { useGetClienteMutation } from '../../../store/api/endpoints/clientesApiSlice'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import TituloMini from '../../elementos/TituloMini'

import StoreIcon from '@mui/icons-material/Store'

export default function ClienteUpdateContacto() {

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const urlParams = useParams()

    const [
        getCliente,
        {
            data: responseGetCliente,
            isUninitialized: isUninitializedGetCliente,
            isLoading: isLoadingGetCliente,
            isSuccess: isSuccessGetCliente,
            isError: isErrorGetCliente,
        }
    ] = useGetClienteMutation()
    
    useEffect(() => {
        getCliente({ id: urlParams.id })
    }, [])

    if (permisosDelUsuario.indexOf(7) === -1) return <SinPermisos />

    if ( isUninitializedGetCliente || isLoadingGetCliente ) return <Loading />

    if (permisosDelUsuario.indexOf(7) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Link underline="hover" color="inherit" to={"/clientes/" + urlParams.id}>
                            {responseGetCliente.dominio}
                        </Link>
                        <Typography color="text.primary">Rutas</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <Container className="nuevoRegistro" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <br />
                        <Grid item xs={12} >
                            <TituloMini titulo="Archivos e Imágenes">Estos archivos conforman la vista de clientes.</TituloMini>
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="Logo"
                                img={responseGetCliente.rutas.logo}
                                descripcion="Es el logo de la marca. Esta imágen aparecerá en la vista de cliente."
                                confirmDelete={
                                    (responseGetCliente.rutas.logo !== '' ?
                                        {
                                            httpDelete: 'clientes/' + urlParams.id + '/ruta/logo',
                                            returnUrl: '/clientes/' + urlParams.id
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (responseGetCliente.rutas.logo === '' ?
                                        ('/clientes/' + urlParams.id + '/rutas/logo')
                                        : '')
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="Socials"
                                img={responseGetCliente.rutas.socials}
                                descripcion="Es la imágen que aparece al compartir el link por whatsapp, facebook, instagram o redes sociales."
                                confirmDelete={
                                    (responseGetCliente.rutas.socials !== '' ?
                                        {
                                            httpDelete: 'clientes/' + urlParams.id + '/ruta/socials',
                                            returnUrl: '/clientes/' + urlParams.id
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (responseGetCliente.rutas.socials === '' ?
                                        ('/clientes/' + urlParams.id + '/rutas/socials')
                                        : '')
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="Favicon"
                                img={responseGetCliente.rutas.favicon}
                                descripcion="Es el icono que aparece en la pestaña del navegador, tanto en la vista de clientes como administradores."
                                confirmDelete={
                                    (responseGetCliente.rutas.favicon !== '' ?
                                        {
                                            httpDelete: 'clientes/' + urlParams.id + '/ruta/favicon',
                                            returnUrl: '/clientes/' + urlParams.id
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (responseGetCliente.rutas.favicon === '' ?
                                        ('/clientes/' + urlParams.id + '/rutas/favicon')
                                        : '')
                                }
                            />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <Card
                                titulo="CSS"
                                descripcion="Archivo de estilos si la marca pide personalizar la vista de cliente. No se utiliza casi nunca."
                                confirmDelete={
                                    (responseGetCliente.rutas.css !== '' ?
                                        {
                                            httpDelete: 'clientes/' + urlParams.id + '/ruta/css',
                                            returnUrl: '/clientes/' + urlParams.id
                                        }
                                        : '')
                                }
                                rutaPencil={
                                    (responseGetCliente.rutas.css === '' ?
                                        ('/clientes/' + urlParams.id + '/rutas/css')
                                        : '')
                                }
                            />
                        </Grid>



                    </Grid>
                </Container>
            
        </>
    )

}