import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import SinPermisos from '../../vistas/auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetVendedoresMutation } from '../../../store/api/endpoints/vendedoresApiSlice'
import { useCreateClienteMutation } from '../../../store/api/endpoints/clientesApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'
import Select from '../../elementos/Select'
import Divider from '../../elementos/Divider'

import '../../../assets/css/vistaFormulario.css'

import StoreIcon from '@mui/icons-material/Store'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import CheckIcon from '@mui/icons-material/Check'
import PersonIcon from '@mui/icons-material/Person'
import HttpIcon from '@mui/icons-material/Http'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import LanguageIcon from '@mui/icons-material/Language'
import PlaceIcon from '@mui/icons-material/Place'

export default function ClienteNuevo() {

    const dispatch = useDispatch();
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()

    const [
        getVendedores,
        {
            data: responseVendedores,
            isUninitialized: isUninitializedVendedores,
            isLoading: isLoadingVendedores,
            isSuccess: isSuccessVendedores,
            isError: isErrorVendedores,
        }
    ] = useGetVendedoresMutation()

    const [
        createCliente,
        {
            data: responseCreaCliente,
            isUninitialized: isUninitializedCreaCliente,
            isLoading: isLoadingCreaCliente,
            isSuccess: isSuccessCreaCliente,
            isError: isErrorCreaCliente,
            error: errorDataCreaCliente
        }
    ] = useCreateClienteMutation()


    const [marca_nombre, setMarcaNombre] = useState('')
    const [marca_dominio, setMarcaDominio] = useState('')
    const [marca_vendedor, setMarcaVendedor] = useState('')
    const [marca_lenguaje, setMarcaLenguaje] = useState('ar')
    const [marca_email, setMarcaEmail] = useState('')
    const [marca_tel_whatsapp, setMarcaTelWhatsapp] = useState('')
    const [marca_tel_fijo, setMarcaTelFijo] = useState('')
    const [marca_direccion, setMarcaDireccion] = useState('')
    const [marca_mapa, setMarcaMapa] = useState('')

    const [contacto_nombre, setContactoNombre] = useState('')
    const [contacto_tel_fijo, setContactoTelFijo] = useState('')
    const [contacto_tel_whatsapp, setContactoTelWhatsapp] = useState('')
    const [contacto_email, setContactoEmail] = useState('')
    const [contacto_descripcion, setContactoDescripcion] = useState('')


    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            marca: {
                dominio: marca_dominio,
                vendedor: marca_vendedor,
                lenguaje: marca_lenguaje,
                nombre: marca_nombre,
                email: marca_email,
                tel_whatsapp: marca_tel_whatsapp,
                tel_fijo: marca_tel_fijo,
                mapa: marca_mapa,
                direccion: marca_direccion,
            },
            contacto: {
                nombre: contacto_nombre,
                email: contacto_email,
                tel_whatsapp: contacto_tel_whatsapp,
                tel_fijo: contacto_tel_fijo,
                descripcion: contacto_descripcion,
            }
        }

        createCliente(body)


    }

    useEffect(() => {
        getVendedores({})
    }, [])

    useEffect(() => {

        if (isSuccessCreaCliente) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el cliente!"
            }))

            navigate("/clientes")

        }

        if (isErrorCreaCliente) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaCliente.data.message
            }))
        }

    }, [
        isSuccessCreaCliente,
        isErrorCreaCliente
    ])

    if (permisosDelUsuario.indexOf(5) === -1) return <SinPermisos />

    if (isUninitializedVendedores || isLoadingVendedores || isLoadingCreaCliente) return <Loading />

    if (permisosDelUsuario.indexOf(5) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/clientes">
                            Clientes
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<StoreIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h3>Info de la Marca / Empresa</h3>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre de Marca"
                                icon={<StoreIcon />}
                                value={marca_nombre}
                                onChange={setMarcaNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Dominio"
                                icon={<HttpIcon />}
                                value={marca_dominio}
                                onChange={setMarcaDominio}
                                restricciones={['alfanumerico']}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                label="Vendedor"
                                icon={<PersonIcon />}
                                value={marca_vendedor}
                                options={responseVendedores.map(function (e) {
                                    return {
                                        value: e._id,
                                        label: e.nombre
                                    }
                                })}
                                onChange={setMarcaVendedor}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Email"
                                icon={<MailOutlineIcon />}
                                value={marca_email}
                                onChange={setMarcaEmail}
                                type="mail"
                                restricciones={['email']}
                                helperText="Sin email colocar #."
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Whatsapp"
                                icon={<WhatsAppIcon />}
                                helperText="Ej: 5491112341234. Sin tel colocar #."
                                value={marca_tel_whatsapp}
                                onChange={setMarcaTelWhatsapp}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Tel Fijo"
                                icon={<PhoneEnabledIcon />}
                                helperText="Ej: 5491112341234. Sin tel colocar #."
                                value={marca_tel_fijo}
                                onChange={setMarcaTelFijo}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Dirección"
                                icon={<PlaceIcon />}
                                value={marca_direccion}
                                onChange={setMarcaDireccion}
                                helperText="Ej: Av San Martín 123, CABA. Sin dirección '#'."
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Mapa"
                                icon={<PlaceIcon />}
                                value={marca_mapa}
                                onChange={setMarcaMapa}
                                helperText="https://goo.gl/maps/msKkvo47bC7YKXUA7. Sin mapa colocar #."
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                label="Lenguaje"
                                icon={<LanguageIcon />}
                                value={marca_lenguaje}
                                options={[{ value: 'ar', label: 'Argentina' }]}
                                onChange={setMarcaLenguaje}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h3>Info del Contacto / Dueño</h3>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre de Contacto"
                                icon={<PersonIcon />}
                                value={contacto_nombre}
                                onChange={setContactoNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Email"
                                icon={<MailOutlineIcon />}
                                value={contacto_email}
                                onChange={setContactoEmail}
                                type="mail"
                                restricciones={['email']}
                                helperText="Sin email #."
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Whatsapp"
                                icon={<WhatsAppIcon />}
                                helperText="Ej: 5491112341234. Sin tel #."
                                value={contacto_tel_whatsapp}
                                onChange={setContactoTelWhatsapp}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Tel Fijo"
                                icon={<PhoneEnabledIcon />}
                                helperText="Ej: 5491112341234. Sin tel #."
                                value={contacto_tel_fijo}
                                onChange={setContactoTelFijo}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}