import { tiendasApi } from '../../api'

export const clientesRutasApiSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        createArchivo: builder.mutation({
            query({idCliente, carpeta, body}){
                return {
                    url: '/clientes/' + idCliente + '/ruta-crop/' + carpeta,
                    method: 'PUT',
                    body: body,
                }
            }
        }),
    })
})

export const {
    useCreateArchivoMutation,
} = clientesRutasApiSlice