import { configureStore  } from "@reduxjs/toolkit";

import { setupListeners } from '@reduxjs/toolkit/query';
import { tiendasApi } from './api/api';

import { authSlice } from './slices/auth';
import { sidebarSlice } from './slices/sidebar';
import { feedbackSlice } from "./slices/feedback";


export const store = configureStore({
    reducer: {
        [tiendasApi.reducerPath]: tiendasApi.reducer,

        auth: authSlice.reducer,
        sidebar: sidebarSlice.reducer,
        feedback: feedbackSlice.reducer,

    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(tiendasApi.middleware),
});

setupListeners(store.dispatch);