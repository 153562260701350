import { React, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import { useSelector } from 'react-redux';
import { getStatusSidebar } from '../../store/slices/sidebar';
import { getUsuarioPermisos } from '../../store/slices/auth';

import '../../assets/css/Sidebar.css';

import { SidebarData } from './SidebarData';

export default function Sidebar() {

    const [submenuEstado, setSubmenuEstado] = useState(
        {
            'clientes': false,
        }
    )

    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const statusSidebar = useSelector(getStatusSidebar)

    const handleClickMenu = (submenu) => {

        let temp = submenuEstado

        temp[submenu] = !submenuEstado[submenu]

        setSubmenuEstado(temp)

    }

    return (
        <div id="sidebar" className={(statusSidebar ? 'open' : 'closed')}>
            <ul>
                {SidebarData.map((val, key) => {
                    if (permisosDelUsuario.indexOf(val.permiso) > -1) {

                        if (val.hijos) {

                            return (
                                <Link key={key} to={val.path} onClick={() => handleClickMenu(val.hijos)}>
                                    <li
                                        className="sidebar-row"
                                    >
                                        <div className="icono">
                                            {val.icono}
                                        </div>
                                        <div className="titulo">
                                            {val.titulo}
                                        </div>
                                    </li>
                                </Link>
                            )

                        } else if (val.hijoMenu) {

                            return (
                                <Link key={key} to={val.path} className={(submenuEstado[val.hijoMenu] ? 'hijoAbierto' : 'hijoCerrado')}>
                                    <li
                                        className="sidebar-row hijo"
                                    >
                                        <div className="icono">
                                            {val.icono}
                                        </div>
                                        <div className="titulo">
                                            {val.titulo}
                                        </div>
                                    </li>
                                </Link>
                            )

                        } else {

                            return (
                                <Link key={key} to={val.path}>
                                    <li
                                        className="sidebar-row"
                                    >
                                        <div className="icono">
                                            {val.icono}
                                        </div>
                                        <div className="titulo">
                                            {val.titulo}
                                        </div>
                                    </li>
                                </Link>
                            )

                        }

                    }
                })}
            </ul>
        </div>
    )

}
