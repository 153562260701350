import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';

import { getUsuarioPermisos } from '../../../store/slices/auth'
import Loading from '../../elementos/Loading'
import SinPermisos from '../../vistas/auth/SinPermisos'
import { openFeedback } from '../../../store/slices/feedback'

import { useGetPermisosMutation } from '../../../store/api/endpoints/permisosApiSlice'
import { useCreateUsuarioMutation } from '../../../store/api/endpoints/usuariosApiSlice'


import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import HeaderVista from '../../elementos/HeaderVista'
import Input from '../../elementos/Input'
import CheckboxList from '../../elementos/CheckboxList'

import '../../../assets/css/vistaFormulario.css'

import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import PercentIcon from '@mui/icons-material/Percent'
import PasswordIcon from '@mui/icons-material/Password'
import CheckIcon from '@mui/icons-material/Check'

export default function UsuarioNuevo() {

    const dispatch = useDispatch();
    const permisosDelUsuario = useSelector(getUsuarioPermisos)
    const navigate = useNavigate()

    const [
        getPermisos,
        {
            data: responsePermisos,
            isUninitialized: isUninitializedPermisos,
            isLoading: isLoadingPermisos,
            isSuccess: isSuccessPermisos,
            isError: isErrorPermisos,
        }
    ] = useGetPermisosMutation()

    const [
        createUsuario,
        {
            data: responseCreaUsuario,
            isUninitialized: isUninitializedCreaUsuario,
            isLoading: isLoadingCreaUsuario,
            isSuccess: isSuccessCreaUsuario,
            isError: isErrorCreaUsuario,
            error: errorDataCreaUsuario
        }
    ] = useCreateUsuarioMutation()


    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [tel_whatsapp, setTelWhatsapp] = useState('');
    const [comision, setComision] = useState('');
    const [contrasena1, setContrasena1] = useState('');
    const [contrasena2, setContrasena2] = useState('');
    const [permisos, setPermisos] = useState([]);




    const handleSubmit = (e) => {

        e.preventDefault();

        let body = {
            nombre,
            email,
            tel_whatsapp,
            comision,
            contrasena1,
            contrasena2,
            permisos,
        }

        createUsuario(body)


    }

    useEffect(() => {
        getPermisos({})
    }, [])

    useEffect(() => {

        if (isSuccessCreaUsuario) {

            dispatch(openFeedback({
                severity: 'success',
                message: "¡Se ha creado el usuario!"
            }))

            navigate("/usuarios")

        }

        if (isErrorCreaUsuario) {
            dispatch(openFeedback({
                severity: 'error',
                message: errorDataCreaUsuario.data.message
            }))
        }

    }, [
        isSuccessCreaUsuario,
        isErrorCreaUsuario
    ])

    if (permisosDelUsuario.indexOf(1) === -1) return <SinPermisos />

    if (isUninitializedPermisos || isLoadingPermisos || isLoadingCreaUsuario) return <Loading />

    if (permisosDelUsuario.indexOf(1) !== -1) return (
        <>
            <HeaderVista
                titulo={
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" to="/usuarios">
                            Usuarios
                        </Link>
                        <Typography color="text.primary">Nuevo</Typography>
                    </Breadcrumbs>
                }
                icon={<SupportAgentIcon />}
            />
            <form onSubmit={handleSubmit}>
                <Container className="vistaFormulario formulario" component="main" maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Nombre"
                                icon={<AccountCircle />}
                                value={nombre}
                                onChange={setNombre}
                                type="text"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Email"
                                icon={<MailOutlineIcon />}
                                value={email}
                                onChange={setEmail}
                                type="mail"
                                restricciones={['email']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Whatsapp"
                                icon={<WhatsAppIcon />}
                                helperText="Ej: 5491112341234"
                                value={tel_whatsapp}
                                onChange={setTelWhatsapp}
                                type="tel"
                                restricciones={['soloNumeros']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Comisión"
                                icon={<PercentIcon />}
                                helperText="Ej: 0.1"
                                value={comision}
                                onChange={setComision}
                                type="tel"
                                restricciones={['soloNumerosDecimales']}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Contraseña"
                                icon={<PasswordIcon />}
                                value={contrasena1}
                                onChange={setContrasena1}
                                type="password"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Input
                                label="Repetir Contraseña"
                                icon={<PasswordIcon />}
                                value={contrasena2}
                                onChange={setContrasena2}
                                type="password"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <CheckboxList
                                titulo="Permisos"
                                opciones={responsePermisos}
                                elegidos={permisos}
                                handleChange={setPermisos}
                            />
                        </Grid>
                    </Grid>
                </Container>

                <Container className="vistaFormulario footer" component="main" maxWidth="lg">
                    <Grid container>
                        <Grid className="botones" item xs={12} >
                            <Button type="submit" variant="contained" color="success" startIcon={<CheckIcon />} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        </>
    )

}