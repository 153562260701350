import { React } from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Tabla(props) {

  if (!props.headers) return(<>a</>)


  return (
    <TableContainer component={Paper} className="tablaM">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.headers.map((item, index) => {
              return (<TableCell key={index} align={props.align[index]}>{item}</TableCell>)
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.body.map((item, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {
                item.map((item2, index) => {
                  return (<TableCell align={props.align[index]} key={index} component="th" scope="row">{item2}</TableCell>)
                })
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}